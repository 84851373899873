<div
  class="text-editor"
  [ngClass]="{ invalid: control.errors && control.touched }">
  <quill-editor
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [modules]="modules"
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="onContentChanged($event)"
    (focus)="onFocus()"
    (blur)="onBlur()">
  </quill-editor>
  <mat-error *ngIf="control.hasError('required') && control.touched">
    This field is required.
  </mat-error>
  <mat-error *ngIf="control.hasError('variableFormat')">
    Invalid format of variable, please check.
  </mat-error>
  <mat-error *ngIf="control.hasError('bracketSequence')">
    Invalid bracket sequence.
  </mat-error>
  <mat-error *ngIf="control.hasError('invalidVariable')">
    Please check the correctness of variable(s).
  </mat-error>
  <mat-error *ngIf="control.hasError('spaceIncluded')">
    Please define the meta tag in one line.
  </mat-error>
</div>
