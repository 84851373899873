import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NotificationLevel, EventType } from '../enums/notifications.enum';

export const NOTIFICATION_LEVELS = [
  {
    id: NotificationLevel.General,
    value: _('applicants.notification.level.General'),
  },
  {
    id: NotificationLevel.Financial,
    value: _('applicants.notification.level.Financial'),
  },
  {
    id: NotificationLevel.Tickets,
    value: _('applicants.notification.level.Tickets'),
  },
  {
    id: NotificationLevel.Applicants,
    value: _('applicants.notification.level.Applicants'),
  },
];

export const EVENT_TYPES = [
  {
    id: EventType.ConfirmRegistration,
    value: _('applicants.notification.type.ConfirmRegistration'),
  },
  {
    id: EventType.SuccessRegistration,
    value: _('applicants.notification.type.SuccessRegistration'),
  },
  {
    id: EventType.PasswordReset,
    value: _('applicants.notification.type.PasswordReset'),
  },
  {
    id: EventType.PasswordChanged,
    value: _('applicants.notification.type.PasswordChanged'),
  },
  {
    id: EventType.UsernameChanged,
    value: _('applicants.notification.type.UsernameChanged'),
  },
  {
    id: EventType.SuccessLogin,
    value: _('applicants.notification.type.SuccessLogin'),
  },
  {
    id: EventType.FailedLogin,
    value: _('applicants.notification.type.FailedLogin'),
  },
  {
    id: EventType.ContactUs,
    value: _('applicants.notification.type.ContactUs'),
  },
  {
    id: EventType.TicketCreated,
    value: _('applicants.notification.type.TicketCreated'),
  },
  {
    id: EventType.TicketClosed,
    value: _('applicants.notification.type.TicketClosed'),
  },
  {
    id: EventType.TicketResponded,
    value: _('applicants.notification.type.TicketResponded'),
  },
  {
    id: EventType.TicketReopened,
    value: _('applicants.notification.type.TicketReopened'),
  },
  {
    id: EventType.NewTicket,
    value: _('applicants.notification.type.NewTicket'),
  },
  {
    id: EventType.CloseTicket,
    value: _('applicants.notification.type.CloseTicket'),
  },
  {
    id: EventType.NewNotification,
    value: _('applicants.notification.type.NewNotification'),
  },
  {
    id: EventType.SendConfirmationSmsCode,
    value: _('applicants.notification.type.SendConfirmationSmsCode'),
  },
  {
    id: EventType.InvoiceCreated,
    value: _('applicants.notification.type.InvoiceCreated'),
  },
  {
    id: EventType.InvoiceCompleted,
    value: _('applicants.notification.type.InvoiceCompleted'),
  },
  {
    id: EventType.InvoiceFailed,
    value: _('applicants.notification.type.InvoiceFailed'),
  },
  {
    id: EventType.UserServiceWillExpireSoon,
    value: _('applicants.notification.type.UserServiceWillExpireSoon'),
  },
  {
    id: EventType.UserServiceFreezeWillEndSoon,
    value: _('applicants.notification.type.UserServiceFreezeWillEndSoon'),
  },
  {
    id: EventType.UserServiceFrozen,
    value: _('applicants.notification.type.UserServiceFrozen'),
  },
  {
    id: EventType.UserServiceAutorenewed,
    value: _('applicants.notification.type.UserServiceAutorenewed'),
  },
  {
    id: EventType.UserServiceExpired,
    value: _('applicants.notification.type.UserServiceExpired'),
  },
  {
    id: EventType.UnsuccessfulServiceActivation,
    value: _('applicants.notification.type.UnsuccessfulServiceActivation'),
  },
  {
    id: EventType.PaymentBankTransfer,
    value: _('applicants.notification.type.PaymentBankTransfer'),
  },
  {
    id: EventType.CreateVerificationSettings,
    value: _('applicants.notification.type.CreateVerificationSettings'),
  },
  {
    id: EventType.EmailConfirmation,
    value: _('applicants.notification.type.EmailConfirmation'),
  },
  {
    id: EventType.UploadFile,
    value: _('applicants.notification.type.UploadFile'),
  },
  {
    id: EventType.SuccessSocialRegistration,
    value: _('applicants.notification.type.SuccessSocialRegistration'),
  },
  {
    id: EventType.LowVerificationBalance,
    value: _('applicants.notification.type.LowVerificationBalance'),
  },
  {
    id: EventType.EmptyVerificationBalance,
    value: _('applicants.notification.type.EmptyVerificationBalance'),
  },
  {
    id: EventType.VerificationCompleted,
    value: _('applicants.notification.type.VerificationCompleted'),
  },
];

export const EVENT_TYPE_DATA: Record<EventType, { title: string; description: string }> = {
  [EventType.ConfirmRegistration]: {
    title: _('applicants.notification.type.ConfirmRegistration'),
    description: _('applicants.notification.type.ConfirmRegistration.description'),
  },
  [EventType.SuccessRegistration]: {
    title: _('applicants.notification.type.SuccessRegistration'),
    description: _('applicants.notification.type.SuccessRegistration.description'),
  },
  [EventType.PasswordReset]: {
    title: _('applicants.notification.type.PasswordReset'),
    description: _('applicants.notification.type.PasswordReset.description'),
  },
  [EventType.PasswordChanged]: {
    title: _('applicants.notification.type.PasswordChanged'),
    description: _('applicants.notification.type.PasswordChanged.description'),
  },
  [EventType.UsernameChanged]: {
    title: _('applicants.notification.type.UsernameChanged'),
    description: _('applicants.notification.type.UsernameChanged.description'),
  },
  [EventType.SuccessLogin]: {
    title: _('applicants.notification.type.SuccessLogin'),
    description: _('applicants.notification.type.SuccessLogin.description'),
  },
  [EventType.FailedLogin]: {
    title: _('applicants.notification.type.FailedLogin'),
    description: _('applicants.notification.type.FailedLogin.description'),
  },
  [EventType.ContactUs]: {
    title: _('applicants.notification.type.ContactUs'),
    description: _('applicants.notification.type.ContactUs.description'),
  },
  [EventType.TicketCreated]: {
    title: _('applicants.notification.type.TicketCreated'),
    description: _('applicants.notification.type.TicketCreated.description'),
  },
  [EventType.TicketClosed]: {
    title: _('applicants.notification.type.TicketClosed'),
    description: _('applicants.notification.type.TicketClosed.description'),
  },
  [EventType.TicketResponded]: {
    title: _('applicants.notification.type.TicketResponded'),
    description: _('applicants.notification.type.TicketResponded.description'),
  },
  [EventType.TicketReopened]: {
    title: _('applicants.notification.type.TicketReopened'),
    description: _('applicants.notification.type.TicketReopened.description'),
  },
  [EventType.NewTicket]: {
    title: _('applicants.notification.type.NewTicket'),
    description: _('applicants.notification.type.NewTicket.description'),
  },
  [EventType.CloseTicket]: {
    title: _('applicants.notification.type.CloseTicket'),
    description: _('applicants.notification.type.CloseTicket.description'),
  },
  [EventType.NewNotification]: {
    title: _('applicants.notification.type.NewNotification'),
    description: _('applicants.notification.type.NewNotification.description'),
  },
  [EventType.SendConfirmationSmsCode]: {
    title: _('applicants.notification.type.SendConfirmationSmsCode'),
    description: _('applicants.notification.type.SendConfirmationSmsCode.description'),
  },
  [EventType.InvoiceCreated]: {
    title: _('applicants.notification.type.InvoiceCreated'),
    description: _('applicants.notification.type.InvoiceCreated.description'),
  },
  [EventType.InvoiceCompleted]: {
    title: _('applicants.notification.type.InvoiceCompleted'),
    description: _('applicants.notification.type.InvoiceCompleted.description'),
  },
  [EventType.InvoiceFailed]: {
    title: _('applicants.notification.type.InvoiceFailed'),
    description: _('applicants.notification.type.InvoiceFailed.description'),
  },
  [EventType.UserServiceWillExpireSoon]: {
    title: _('applicants.notification.type.UserServiceWillExpireSoon'),
    description: _('applicants.notification.type.UserServiceWillExpireSoon.description'),
  },
  [EventType.UserServiceFreezeWillEndSoon]: {
    title: _('applicants.notification.type.UserServiceFreezeWillEndSoon'),
    description: _('applicants.notification.type.UserServiceFreezeWillEndSoon.description'),
  },
  [EventType.UserServiceFrozen]: {
    title: _('applicants.notification.type.UserServiceFrozen'),
    description: _('applicants.notification.type.UserServiceFrozen.description'),
  },
  [EventType.UserServiceAutorenewed]: {
    title: _('applicants.notification.type.UserServiceAutorenewed'),
    description: _('applicants.notification.type.UserServiceAutorenewed.description'),
  },
  [EventType.UserServiceExpired]: {
    title: _('applicants.notification.type.UserServiceExpired'),
    description: _('applicants.notification.type.UserServiceExpired.description'),
  },
  [EventType.UnsuccessfulServiceActivation]: {
    title: _('applicants.notification.type.UnsuccessfulServiceActivation'),
    description: _('applicants.notification.type.UnsuccessfulServiceActivation.description'),
  },
  [EventType.PaymentBankTransfer]: {
    title: _('applicants.notification.type.PaymentBankTransfer'),
    description: _('applicants.notification.type.PaymentBankTransfer.description'),
  },
  [EventType.CreateVerificationSettings]: {
    title: _('applicants.notification.type.CreateVerificationSettings'),
    description: _('applicants.notification.type.CreateVerificationSettings.description'),
  },
  [EventType.EmailConfirmation]: {
    title: _('applicants.notification.type.EmailConfirmation'),
    description: _('applicants.notification.type.EmailConfirmation.description'),
  },
  [EventType.UploadFile]: {
    title: _('applicants.notification.type.UploadFile'),
    description: _('applicants.notification.type.UploadFile.description'),
  },
  [EventType.SuccessSocialRegistration]: {
    title: _('applicants.notification.type.SuccessSocialRegistration'),
    description: _('applicants.notification.type.SuccessSocialRegistration.description'),
  },
  [EventType.LowVerificationBalance]: {
    title: _('applicants.notification.type.LowVerificationBalance'),
    description: _('applicants.notification.type.LowVerificationBalance.description'),
  },
  [EventType.EmptyVerificationBalance]: {
    title: _('applicants.notification.type.EmptyVerificationBalance'),
    description: _('applicants.notification.type.EmptyVerificationBalance.description'),
  },
  [EventType.VerificationCompleted]: {
    title: _('applicants.notification.type.VerificationCompleted'),
    description: _('applicants.notification.type.VerificationCompleted.description'),
  },
};

export const LEVEL_ICONS = {
  [NotificationLevel.Applicants]: 'group',
  [NotificationLevel.General]: 'settings_backup_restore',
  [NotificationLevel.Financial]: 'payments',
  [NotificationLevel.Tickets]: 'support',
};
