import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonState } from '../enums/fraud-type.enum';

export const COMMON_STATE = [
  {
    id: CommonState.cancelled,
    value: _('common.state.cancelled'),
  },
  {
    id: CommonState.passed,
    value: _('common.state.passed'),
  },
  {
    id: CommonState.notApplicable,
    value: _('common.state.notApplicable'),
  },
];
