export enum FraudTypeEnum {
  FraudSignalsIsIdentityDocument = 1,
  FraudSignalsSuspiciousWords = 2,
  EvidenceSuspiciousWord = 3,
  EvidenceInconclusiveSuspiciousWord = 4,
  FraudSignalsImageManipulation = 5,
  EvidenceThumbnailUrl = 6,
  EvidenceHostname = 7,
  FraudSignalsOnlineDuplicate = 8,
}

export enum CommonState {
  cancelled = 1,
  passed,
  notApplicable,
}
