import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateParser,
} from '@ngx-translate/core';
import { RoutingModule } from './routing/routing.module';
import { LayoutModule } from './layout/layout.module';
import {
  CustomTranslateDefaultParser,
  CustomTranslationHandler,
  CoreModule,
  LocaleService,
} from '@core';
import { LocalizationEnum } from '@shared';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeZh from '@angular/common/locales/zh';
import localeFR from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeUk from '@angular/common/locales/uk';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CodeInputModule } from 'angular-code-input';

registerLocaleData(localeEN);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localeZh);
registerLocaleData(localeFR);
registerLocaleData(localeUk);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?v=' + Date.now());
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      parser: {
        provide: TranslateParser,
        useClass: CustomTranslateDefaultParser,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler,
      },
    }),
    LayoutModule,
    CoreModule,
    CodeInputModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: { locale: string }) => LocalizationEnum[localeService.locale],
    },
    { provide: MAT_DATE_LOCALE, useValue: `en-GB` },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
