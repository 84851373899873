export enum ServiceType {
  Trial = 1,
  Standard = 2,
  Pro = 3,
  Premium = 4,
}

export enum InvoiceState {
  Pending = 1,
  WaitForConfirm = 2,
  Paid = 3,
  Declined = 4,
  Archived = 5,
}

export enum PaymentMethod {
  Mock = 1,
  Paypal = 2,
  Interkassa = 3,
  Balance = 4,
  Bonuses = 5,
  BankTransfer = 6,
  Stripe = 7,
}
