<image-cropper
  class="cropper"
  [imageFile]="data.image"
  [maintainAspectRatio]="true"
  [imageQuality]="50"
  [aspectRatio]="data.rounded ? 1 : data.aspectRation || 4 / 4"
  [roundCropper]="data.rounded ?? false"
  format="png"
  (imageCropped)="imageCropped($event)"></image-cropper>

<div class="cropper-actions">
  <kyc-button class="margin-right-3" [mat-dialog-close]="false"
    >{{ 'global.cancel' | translate }}
  </kyc-button>
  <kyc-button [mat-dialog-close]="{ croppedImage: croppedImage, file: file }">{{
    'global.confirm' | translate
  }}</kyc-button>
</div>
