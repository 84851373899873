export interface ICommonProps {
  readonly _id?: number;
  readonly _created?: string;
  readonly _updated?: string;
  readonly _etag?: string;
  readonly _status?: string;
}

export interface IResponse<T> {
  _items: T[];
  _meta: IMetaData;
}

interface IMetaData {
  max_results: number;
  page: number | string;
  total: number;
}

export interface IApiError {
  _error: {
    code: number;
    message: IErrorMessage;
  };
  _status: string;
}

export interface IErrorMessage {
  field: string;
  name: string;
}
