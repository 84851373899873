import { ICommonProps } from './API.interface';
import {
  DocumentCapturedEnum,
  DocumentTypeEnum,
  SelfieTypeEnum,
  VerificationEnum,
  FieldTypeEnum,
  FormState,
  VerificationType,
  POADocumentTypes,
} from '../enums';
import {
  IVerificationDocumentSettings,
  IVerificationDocuments,
} from './verification-documents.interface';

export interface IVerification extends ICommonProps {
  name: string;
  description: string;
  type?: VerificationType;
  selfie: SelfieTypeEnum;
  documents: DocumentTypeEnum[];
  address_documents: POADocumentTypes[];
  address_documents_captured: DocumentCapturedEnum;
  documents_captured: DocumentCapturedEnum;
  document_settings?: IVerificationDocumentSettings;
  country_docs?: IVerificationDocuments;
  form: IStepForm[];
  steps: VerificationEnum[];
  company_id?: number;
  user_id?: number;
  link?: string;
  auto_approve: VerificationEnum[];
}

export interface IStepForm {
  placeholder: string;
  id: number;
  fields: IFormField[];
  passed?: boolean;
  rejectComment?: string;
  state?: FormState;
}

export interface IFormField {
  required: boolean;
  placeholder: string;
  type: FieldTypeEnum;
  options?: string[];
  value?: string;
}

export interface IRegenerateLinkData {
  applicant_id?: number;
  verification_map_id?: number;
}

export interface IVerificationProgressStep<T> {
  id: T;
  status: 'done' | 'current' | 'pending' | 'rejected';
  order: number;
}
