import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionType } from '@modules/applicants/interfaces';

export const APPLICANT_ACTIONS = [
  {
    id: ActionType.WrongEmailConfirmationCode,
    value: _('applicants.actions.type.WrongEmailConfirmationCode'),
  },
  {
    id: ActionType.EmailVerified,
    value: _('applicants.actions.type.EmailVerified'),
  },
  {
    id: ActionType.PhoneVerificationRequested,
    value: _('applicants.actions.type.PhoneVerificationRequested'),
  },
  {
    id: ActionType.WrongPhoneConfirmationCode,
    value: _('applicants.actions.type.WrongPhoneConfirmationCode'),
  },
  {
    id: ActionType.PhoneVerified,
    value: _('applicants.actions.type.PhoneVerified'),
  },
  {
    id: ActionType.FromStepPassed,
    value: _('applicants.actions.type.FromStepPassed'),
  },
  {
    id: ActionType.FromPassed,
    value: _('applicants.actions.type.FromPassed'),
  },
  {
    id: ActionType.FromStepVerified,
    value: _('applicants.actions.type.FromStepVerified'),
  },
  {
    id: ActionType.FromVerified,
    value: _('applicants.actions.type.FromVerified'),
  },
  {
    id: ActionType.FromStepRejected,
    value: _('applicants.actions.type.FromStepRejected'),
  },
  {
    id: ActionType.SelfiePassed,
    value: _('applicants.actions.type.SelfiePassed'),
  },
  {
    id: ActionType.SelfieRejected,
    value: _('applicants.actions.type.SelfieRejected'),
  },
  {
    id: ActionType.SelfieVerified,
    value: _('applicants.actions.type.SelfieVerified'),
  },
  {
    id: ActionType.DocumentPassed,
    value: _('applicants.actions.type.DocumentPassed'),
  },
  {
    id: ActionType.DocumentSideRejected,
    value: _('applicants.actions.type.DocumentSideRejected'),
  },
  {
    id: ActionType.DocumentSideVerified,
    value: _('applicants.actions.type.DocumentSideVerified'),
  },
  {
    id: ActionType.DocumentVerified,
    value: _('applicants.actions.type.DocumentVerified'),
  },
  {
    id: ActionType.VerificationCompleted,
    value: _('applicants.actions.type.VerificationCompleted'),
  },
  {
    id: ActionType.ApplicantVerified,
    value: _('applicants.actions.type.ApplicantVerified'),
  },
];
