import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentCheck } from '../enums/document-type.enum';

export const FACE_SIMILARITY = [
  {
    id: DocumentCheck.Unknown,
    value: _('applicant.faceMatch.similarity.unknown'),
  },
  {
    id: DocumentCheck.Low,
    value: _('applicant.faceMatch.similarity.low'),
  },
  {
    id: DocumentCheck.Medium,
    value: _('applicant.faceMatch.similarity.medium'),
  },
  {
    id: DocumentCheck.High,
    value: _('applicant.faceMatch.similarity.high'),
  },
];
