export enum LocalizationEnum {
  en = 'en-US-POSIX',
  de = 'de-DE',
  es = 'es-ES',
}

export enum LanguageEnum {
  English = 'en',
  German = 'de',
  Spanish = 'es',
}

export enum LanguageIdEnum {
  en = 1,
  uk = 2,
}

export enum BrowserLanguageEnum {
  'en-GB' = LanguageEnum.English,
  'de' = LanguageEnum.German,
  'es' = LanguageEnum.Spanish,
}

export const SHORT_LANG_MAP = {
  [LanguageEnum.English]: 'EN',
  [LanguageEnum.German]: 'DE',
  [LanguageEnum.Spanish]: 'ES',
};
export const LONG_LANG_MAP = {
  [LanguageEnum.English]: 'English',
  [LanguageEnum.German]: 'German',
  [LanguageEnum.Spanish]: 'Spanish',
};

export const LANG_ID_MAP = {
  [LanguageIdEnum.en]: 'English',
  [LanguageIdEnum.uk]: 'Ukrainian',
};
