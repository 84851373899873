import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { ADMIN_MENU, ISidenav, RoleId, PRIVATE_MENU } from '@shared';
import { AuthService } from '@core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'kyc-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent implements OnInit {
  @Output() toggleNav = new EventEmitter();

  routes$: Observable<ISidenav[]> = this.authService
    .getCurrentUser()
    .pipe(map((user) => (user?.role === RoleId.USER ? PRIVATE_MENU : ADMIN_MENU)));

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}
}
