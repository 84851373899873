import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TicketPriority } from '../enums';

export const TICKET_PRIORITIES = [
  {
    id: TicketPriority.Low,
    value: _('ticket.priority.low'),
  },
  {
    id: TicketPriority.Medium,
    value: _('ticket.priority.medium'),
  },
  {
    id: TicketPriority.High,
    value: _('ticket.priority.high'),
  },
  {
    id: TicketPriority.Critical,
    value: _('ticket.priority.critical'),
  },
];
