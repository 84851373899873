import { ICountry, ISelectOption, EventType } from '@shared';
import { IAction } from '@modules/applicants/interfaces';

export interface IValues {
  applicantActions: ISelectOption<number>[];
  applicantActionsMap: { [key: number]: string };
  countries?: ICountry[];
  countriesSelect?: ISelectOption[];
  countriesMap: { [key: number]: ICountry };
  actionsMap: { [key: number]: IAction };
  feedbackTypes?: ISelectOption<number>[];
  ticketPriorities?: ISelectOption<number>[];
  ticketDepartments?: ISelectOption<number>[];
  ticketDepartmentsMap: { [key: number]: string };
  applicantStatuses?: ISelectOption<number>[];
  applicantStatusesMap?: { [key: number]: string };
  stepStateMap?: { [key: number]: string };
  userRoles?: ISelectOption<number>[];
  userLockedStatus?: ISelectOption<number>[];
  userRolesMap?: { [key: number]: string };
  invoiceStatuses?: ISelectOption<number>[];
  invoiceStatusesMap?: { [key: number]: string };
  paymentMethods?: ISelectOption<number>[];
  paymentMethodsMap?: { [key: number]: string };
  applicantRiskLevels?: ISelectOption<number>[];
  applicantRiskLevelsMap?: { [key: number]: string };
  faceSimilarityMap?: { [key: number]: string };
  tfaTypes?: ISelectOption<number>[];
  fieldTypes?: ISelectOption<number>[];
  userVerificationSteps?: ISelectOption<number>[];
  addressVerificationSteps?: ISelectOption<number>[];
  fullVerificationStepsMap?: { [key: number]: string };
  verificationTypes?: ISelectOption<number>[];
  verificationTypesMap?: { [key: number]: string };
  selfieTypes?: ISelectOption<number>[];
  selfieTypesMap?: { [key: number]: string };
  userStatuses?: ISelectOption<number>[];
  userStatusesMap?: { [key: number]: string };
  fileCapture?: ISelectOption<number>[];
  documentTypes?: ISelectOption<number>[];
  documentTypesMap?: { [key: number]: string };
  addressDocumentTypes?: ISelectOption<number>[];
  addressDocumentTypesMap?: { [key: number]: string };
  allDocumentsTypeMap?: { [key: number]: string };
  serviceTypes?: ISelectOption<number>[];
  serviceTypesMap?: { [key: number]: string };
  defaultFormFields?: ISelectOption<number>[];
  teamMemberStatuses?: ISelectOption<number>[];
  teamMemberRoles?: ISelectOption<number>[];
  notificationTypes: ISelectOption<number>[];
  notificationLevels: ISelectOption<number>[];
  ticketDepartment: ISelectOption<number>[];
  notificationTypesMap: Record<EventType, { title: string; description: string }>;
  fraudTypesMap: { [key: number]: string };
  commonStateMap: { [key: number]: string };
  newsTypes?: ISelectOption<number>[];
  newsTypesMap?: { [key: number]: string };
}
