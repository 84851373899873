<kyc-button
  [matMenuTriggerFor]="menu"
  variant="stroked"
  color="success"
  class="notification-btn">
  <kyc-icon class="notification-btn__icon">notifications</kyc-icon>
  <div
    [matBadge]="total"
    matBadgeColor="warn"
    class="notification-btn__badge"></div>
</kyc-button>
<mat-menu class="notification__menu" #menu="matMenu">
  <div
    class="notification__list"
    *kycLet="notificationDetails$ | async as notificationData">
    <p class="notification__list__title">
      {{ 'applicants.notifications.header.title' | translate }}
    </p>
    <ng-container *ngIf="notifications$ | async as notifications; else loading">
      <ng-container *ngIf="notifications.length; else emptyState">
        <div class="notification" *ngFor="let notification of notifications">
          <kyc-icon class="notification__icon">{{
            LEVEL_ICONS[notification.level]
          }}</kyc-icon>
          <div
            class="notification__body"
            *kycLet="notificationData[notification.event_type] as data">
            <p>
              <b>{{ data.title | translate }}</b>
              {{ data.description | translate }}
            </p>
            <span>{{ notification._created | date: 'MMM d, y, hh:mm' }}</span>
          </div>
          <span class="notification__state"></span>
        </div>

        <kyc-button
          class="notification__more"
          [routerLink]="ROUTES_DATA.NOTIFICATIONS.url">
          {{ 'applicants.notifications.showAll' | translate }}
        </kyc-button>
      </ng-container>
    </ng-container>

    <ng-template #emptyState>
      <div class="notification__empty">
        <kyc-icon size="fit" icon="notification"></kyc-icon>
        <p>{{ 'applicants.notifications.empty' | translate }}</p>
      </div>
    </ng-template>

    <ng-template #loading>
      <ngx-skeleton-loader
        count="3"
        appearance="line"
        [theme]="{ height: '80px' }"></ngx-skeleton-loader>
    </ng-template>
  </div>
</mat-menu>
