import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services';
import { Observable } from 'rxjs';

export const VERIFICATION_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken: string | null = this.authService.getAuthToken();
    const verificationToken: string | null = this.authService.getVerificationToken();
    const token = request.context.get(VERIFICATION_TOKEN) ? verificationToken : authToken;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }
}
