import { ICommonProps } from './API.interface';
import { FileType } from '../enums';

export interface IKeyValue<T, N> {
  key: T;
  value: N;
}

export interface IObjectWithParams {
  [key: string]: any;
}

export interface IQuery {
  [key: string]: any;
  where?: any;
  sort?: any;
  page?: number;
  max_results?: number;
  offset?: number;
}

export interface IAttachment extends ICommonProps {
  user_id: number;
  fs_name: string;
  public_name: string;
  desc: string;
  type: FileType;
  ext: string;
  size: number;
  count_view: number;
  count_download: number;
}
