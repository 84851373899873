import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kyc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon!: string;
  @Input() color: ThemePalette;
  @Input() size: 'auto' | 'fit' | null = 'auto';
  @Input() fontSize = 24;

  private readonly defaultExtension = 'svg';
  private readonly baseUrl = 'assets/icons';

  constructor(readonly sanitizer: DomSanitizer, readonly registry: MatIconRegistry) {}

  ngOnChanges() {
    const name = this.icon;
    const svgUrl = `${this.baseUrl}/${name}.${this.defaultExtension}`;
    const safeIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(svgUrl);
    this.registry.addSvgIcon(name, safeIconUrl);
  }
}
