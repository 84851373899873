import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTES_DATA } from '@shared';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route.data.isAdmin);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check(childRoute.data.isAdmin);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route.data.isAdmin);
  }

  // can visit page if user is not authorized
  private _check(isAdmin: boolean): boolean {
    const authed = this.authService.isAuthorized();
    if (authed) {
      this.router.navigateByUrl(ROUTES_DATA.HOME.url);
    }
    return !authed;
  }
}
