import { VerificationEnum } from '../enums';

export const STEP_ICON_MAP: Record<VerificationEnum, string> = {
  [VerificationEnum.ConfirmEmail]: 'mail',
  [VerificationEnum.Selfie]: 'selfie',
  [VerificationEnum.ConfirmPhone]: 'smartphone',
  [VerificationEnum.Documents]: 'id-card',
  [VerificationEnum.SurveyForm]: 'form',
  [VerificationEnum.AddressDocuments]: 'receipt_long',
  [VerificationEnum.GpsLocation]: 'home_pin',
  [VerificationEnum.AddressForm]: 'home',
};
