import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ApiErrorResponse, deepFind, SNACK_BARS, SnackBarService, ROUTES_DATA } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private snackBarService: SnackBarService,
    private zone: NgZone,
    private router: Router
  ) {}

  // handle error and show snackbar with message
  handleError(error: ApiErrorResponse | Error) {
    if (error instanceof ApiErrorResponse) {
      this.zone.run(() => {
        if (error.status === 401) {
          this.router.navigate([ROUTES_DATA.AUTH.children.SIGN_IN.url]);
        } else {
          if (error.getErrorMessage()) {
            try {
              this.snackBarService.openErrorSnackBar(deepFind(SNACK_BARS, error.getErrorMessage()));
            } catch (e) {
              this.snackBarService.openErrorSnackBar(SNACK_BARS.serverError);
            }
          } else {
            this.snackBarService.openErrorSnackBar(SNACK_BARS.serverError);
          }
        }
      });
    }
    console.error(error);
  }
}
