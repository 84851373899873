import { AlphanumValidator } from './alphanum.validator';
import { GreaterThanOrEqualValidator } from './greater-than-or-equal.validator';
import { GreaterThanValidator } from './greater-than.validator';
import { IntegerValidator } from './integer.validator';
import { LessThanOrEqualValidator } from './less-than-or-equal.validator';
import { LessThanValidator } from './less-than.validator';
import { MinLengthValidator } from './min-length.validator';
import { NumberRangeValidator } from './number-range.validator';
import { SpecialCharactersValidator } from './special-characters.validator';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { EmailFormatValidator } from './email-address.validator';
import { NotConfirmPasswordValidator } from './not-confirm-password.validator';
import { PasswordFormatValidator } from './password.validator';
import { PhoneFormatValidator } from './phone-number.validator';
import { UniqFormArrayFieldValidator } from './uniq-form-array-field.validator';
import { RequiredFileType } from './file-type.validator';
import { DocUniquePropValidator } from './unique-document-prop.validator';

const CUSTOM_VALIDATORS = {
  integer: IntegerValidator,
  alphanum: AlphanumValidator,
  specialChars: SpecialCharactersValidator,
  greaterThan: GreaterThanValidator,
  greaterThanOrEqual: GreaterThanOrEqualValidator,
  lessThan: LessThanValidator,
  lessThanOrEqual: LessThanOrEqualValidator,
  minLength: MinLengthValidator,
  numberRange: NumberRangeValidator,
  confirmPass: ConfirmPasswordValidator,
  notConfirmPass: NotConfirmPasswordValidator,
  emailFormat: EmailFormatValidator,
  passwordFormat: PasswordFormatValidator,
  phoneFormat: PhoneFormatValidator,
  uniqFormArrayFieldValidator: UniqFormArrayFieldValidator,
  docUniquePropValidator: DocUniquePropValidator,
  fileType: RequiredFileType,
};

export { CUSTOM_VALIDATORS as CustomValidators };
