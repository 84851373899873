<p *ngIf="label" class="file-upload__label">{{ label }}</p>
<div class="file-upload">
  <ng-container *ngIf="!(withPreview && imageURL)">
    <kyc-icon class="file-upload__icon" size="fit" [fontSize]="30"
      >upload</kyc-icon
    >
    <span class="file-upload__msg">{{
      file ? file.name : ('file.upload.placeholder' | translate)
    }}</span>
  </ng-container>

  <ng-container *ngIf="withPreview">
    <div class="preview" *ngIf="imageURL && imageURL !== ''">
      <img class="preview__img" [src]="imageURL" alt="user document upload" />
      <span (click)="onPreviewRemove()" class="preview__remove" matRipple>
        <kyc-icon>delete</kyc-icon>
      </span>
    </div>
  </ng-container>

  <input
    #fileInput
    class="file-upload__input"
    type="file"
    [accept]="withPreview ? 'image/*' : '*'"
    (change)="showPreview($event)" />
</div>
