export enum VerificationEnum {
  ConfirmEmail = 1,
  ConfirmPhone = 2,
  Selfie = 3,
  Documents = 4,
  SurveyForm = 5,
  AddressForm = 6,
  AddressDocuments = 7,
  GpsLocation = 9,
}

export enum VerificationType {
  User = 1,
  Address = 2,
}

export enum StepState {
  passed = 'passed',
  rejected = 'rejected',
  pending = 'pending',
  waitForApprove = 'waitForApprove',
}

export enum FormState {
  Verified = 1,
  Rejected = 2,
  WaitForApprove = 3,
  Unpassed = 4,
}
