import { Injectable } from '@angular/core';
import { INotification } from '@modules/notifications/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationFactory {
  toRequest(order: INotification): INotification {
    return order;
  }

  fromResponse(order: INotification): INotification {
    return order;
  }
}
