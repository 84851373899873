import { AbstractControl, FormGroup } from '@angular/forms';

export function UniqFormArrayFieldValidator(control: AbstractControl) {
  const { value } = control;
  const parentFields = (control?.parent?.parent?.parent?.controls || []) as FormGroup[];

  const values = new Set();
  const double = new Set();

  parentFields.forEach((c) => {
    const f = c?.get('data')?.get('fieldName');
    const name = f?.value;
    if (name) {
      if (values.has(name)) {
        double.add(name);
      }
      values.add(name);
    }
  });

  parentFields.forEach((c) => {
    const f = c?.get('data')?.get('fieldName');
    const name = f?.value;
    if (values.has(name)) {
      if (double.has(name)) {
        f?.setErrors({ doubleFields: true });
      } else {
        f?.setErrors(null);
      }
    }
  });
  return double.has(value) ? { doubleFields: true } : null;
}
