import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ROUTES_DATA } from '@shared';
import { Observable, of } from 'rxjs';
import { AuthService, GlobalObjectService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private gos: GlobalObjectService,
    private router: Router
  ) {}

  canActivate(_, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }
  canActivateChild(
    _,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  private _check(): Observable<boolean> {
    if (!this.authService.isAuthorized()) {
      this.router.navigate([ROUTES_DATA.AUTH.url]);
      return of(false);
    }
    return of(true);
  }
}
