import { Injectable } from '@angular/core';
import {
  BaseHttpService,
  ILogin2FACode,
  ILogin2FARecoveryCode,
  ILoginData,
  ILoginResponse,
  IRegisterData,
  UserType,
  ILoginTFA_Response,
  IProfile,
  ICommonProps,
  IQuery,
  IResponse,
  ADMIN_LOGGED,
  IUserService,
} from '@shared';
import { HttpClient } from '@angular/common/http';
import { GlobalObjectService } from '@core';
import { tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, catchError, Observable, of, take } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseHttpService {
  currentUser$ = new BehaviorSubject<IProfile | null>(null);
  number_of_verification$ = new BehaviorSubject<number | null>(null);

  constructor(readonly httpClient: HttpClient, private globalObjectService: GlobalObjectService) {
    super('');
  }

  signIn(data: ILoginData) {
    return this.post<ILoginData, ILoginResponse>(data, {}, 'user/login');
  }

  confirmSignIn2FAByCode(data: ILogin2FACode) {
    return this.post<ILogin2FACode, ILoginTFA_Response>(data, {}, 'user/login_tfa').pipe(
      tap(({ token }) => this.setAuthToken(token)),
      switchMap(() => this.invokeUserProfile())
    );
  }

  confirmSignIn2FAByRecoveryCode(data: ILogin2FARecoveryCode) {
    return this.post<ILogin2FARecoveryCode, ILoginTFA_Response>(
      data,
      {},
      'user/login_recovery_code'
    ).pipe(
      tap(({ token }) => this.setAuthToken(token)),
      switchMap(() => this.invokeUserProfile())
    );
  }

  resendSMSFor2FA(user_id: number, user_type: UserType): Observable<void> {
    return this.post({ user_id, user_type }, {}, 'user/login_tfa_resend_sms');
  }

  getUsers(query?: IQuery): Observable<IResponse<IProfile>> {
    return this.get(query, 'user');
  }

  createUser(data: Partial<IRegisterData>): Observable<ICommonProps> {
    return this.post<Partial<IRegisterData>, ICommonProps>(data, {}, 'user');
  }

  editUser(data: Partial<IProfile>, user_id: number): Observable<ICommonProps> {
    return this.patch<Partial<IProfile>, ICommonProps>(data, {}, `user/${user_id}`);
  }

  confirmSignUp(token: string) {
    return this.post({ link: token }, {}, 'user/confirm');
  }

  signOut() {
    this.globalObjectService.removeStorageData(environment.authToken);
    this.globalObjectService.removeStorageData(ADMIN_LOGGED);
    this.clearUserProfile();
  }

  forgotPassword(data: { username: string; user_type: UserType }) {
    return this.post(data, {}, 'user/forgot_password');
  }

  confirmForgotPassword(token: string, new_password: string) {
    return this.post({ link: token, new_password }, {}, 'user/reset_password');
  }

  changePassword(body: { old_password: string; new_password: string }, userId: string | number) {
    return this.post(body, {}, `user/${userId}`);
  }

  setAuthToken(token: string): void {
    this.globalObjectService.setStorageData(environment.authToken, token);
  }

  getAuthToken(): string | null {
    return this.globalObjectService.getStorageData(environment.authToken);
  }

  setVerificationToken(token: string): void {
    this.globalObjectService.setStorageData(environment.verificationToken, token);
  }

  getVerificationToken(): string | null {
    return this.globalObjectService.getStorageData(environment.verificationToken);
  }

  removeVerificationToken() {
    this.globalObjectService.removeStorageData(environment.verificationToken);
  }

  resendActivation(body: { username: string; user_type: UserType }) {
    return this.post(body, {}, 'user/resend_confirmation');
  }

  isAuthorized(): boolean {
    return !!this.getAuthToken();
  }

  /**
   * User
   */

  invokeUserProfile(
    isAmin = this.globalObjectService.getStorageData(ADMIN_LOGGED)
  ): Observable<IProfile | null> {
    return this.get<IProfile | null>(
      isAmin ? {} : { embedded: { company: 1 } },
      isAmin ? 'admin/own' : 'user/own'
    ).pipe(
      tap((user) => {
        this.setCurrentUser(user);
      }),
      take(1),
      catchError(() => {
        this.clearUserProfile();
        return of(null);
      })
    );
  }

  setCurrentUser(userData: IProfile | null = null) {
    this.currentUser$.next(userData);
  }

  getCurrentUser(): Observable<IProfile | null> {
    return this.currentUser$.asObservable();
  }

  clearUserProfile() {
    this.currentUser$.next(null);
  }

  setUserVerifications(count: number | null = null) {
    this.number_of_verification$.next(count);
  }

  getUserVerifications(): Observable<number | null> {
    return this.number_of_verification$.asObservable();
  }

  clearUserVerifications() {
    this.number_of_verification$.next(null);
  }

  getUserService(): Observable<IUserService> {
    return this.get<IUserService>({}, 'user_service/active').pipe(
      tap((resp) => this.setUserVerifications(resp.number_of_verification))
    );
  }
}
