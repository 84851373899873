<img
  *ngIf="avatarPath"
  [ngStyle]="{ width: avatarSize + 'px', height: avatarSize + 'px' }"
  [src]="avatarPath"
  [class.rounded]="roundedImg"
  class="user-logo"
  alt="user logo" />

<div
  *ngIf="!avatarPath"
  [ngStyle]="{ width: avatarSize + 'px', height: avatarSize + 'px' }"
  [class.rounded]="roundedImg"
  class="user-logo">
  <ng-content></ng-content>
</div>
