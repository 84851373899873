import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class InitService {
  constructor(private authService: AuthService) {}

  invokeCurrentUser(): Observable<any> {
    return this.authService.isAuthorized() ? this.authService.invokeUserProfile() : of(true);
  }
}
