import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentCapturedEnum } from '../enums';
export const DOCUMENTS_CAPTURE_TYPES = [
  {
    id: DocumentCapturedEnum.LiveCaptured,
    value: _('capturedType.option.liveCaptured'),
  },
  {
    id: DocumentCapturedEnum.FileUpload,
    value: _('capturedType.option.fileUpload'),
  },
];
