<mat-form-field
  floatLabel="always"
  appearance="outline"
  [@.disabled]="true"
  [ngClass]="{ 'without-label': !label }">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [@.disabled]="true"
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [compareWith]="compareFn"
    [multiple]="multiple"
    [disableOptionCentering]="true"
    (keydown.enter)="onEnterKeyDown($event)">
    <mat-select-trigger *ngIf="displayFn">
      {{ displayFn(options, control.value) }}
    </mat-select-trigger>
    <mat-select-trigger *ngIf="optionWithPrice && options?.length">
      {{ selectedOptionLabel(control.value, options) }}
    </mat-select-trigger>
    <mat-select-trigger *ngIf="optionWithPrice && groupOptions?.length">
      {{ gropedSelectedOptionLabel(control.value, groupOptions) }}
    </mat-select-trigger>
    <ng-container *ngIf="optional">
      <mat-option [value]="null">
        {{ optionalLabel ? optionalLabel : '&mdash;' }}
      </mat-option>
    </ng-container>
    <ng-container *ngFor="let option of options; trackBy: trackByFn">
      <mat-option [value]="valueFn(option)" [disabled]="option?.disabled">
        {{ labelFn(option) }}
      </mat-option>
    </ng-container>
  </mat-select>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'form.errors.required' | translate }}
  </mat-error>
</mat-form-field>
