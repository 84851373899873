import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IKeyValue } from '../../interfaces';
import { LocaleService } from '@core';
import { LanguageEnum, LONG_LANG_MAP, SHORT_LANG_MAP } from '../../enums';
import { map } from 'rxjs';

@Component({
  selector: 'kyc-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSwitcherComponent implements OnInit {
  @Input() shortForm = false;
  currentLang$ = this.locale
    .getLocale()
    .pipe(map((lang) => (this.shortForm ? SHORT_LANG_MAP[lang] : LONG_LANG_MAP[lang])));
  langOptions: IKeyValue<LanguageEnum, string>[];

  constructor(private locale: LocaleService) {}

  ngOnInit(): void {
    this.langOptions = Object.values(LanguageEnum).map((key) => ({
      key,
      value: this.shortForm ? SHORT_LANG_MAP[key] : LONG_LANG_MAP[key],
    }));
  }

  changeLang = (lang: LanguageEnum) => this.locale.useLanguage(lang);
}
