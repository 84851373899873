import { DocumentState } from '../enums';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const DOC_STATE_MAP: Record<DocumentState, { icon: string; name: string; id: string }> = {
  [DocumentState.Verified]: {
    icon: 'check_circle',
    id: 'approved',
    name: _('global.approved'),
  },
  [DocumentState.Rejected]: {
    icon: 'cancel',
    id: 'rejected',
    name: _('global.rejected'),
  },
  [DocumentState.WaitForApprove]: {
    icon: 'schedule',
    id: 'pending',
    name: _('global.waitForApprove'),
  },
};
