import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationService } from '@modules/notifications/services';
import { INotification } from '@modules/notifications/interfaces';
import { map, Observable, pluck } from 'rxjs';
import { ValuesService } from '@core';
import { LEVEL_ICONS, EventType, ROUTES_DATA } from '@shared';

@Component({
  selector: 'kyc-notification-counter',
  templateUrl: './notification-counter.component.html',
  styleUrls: ['./notification-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCounterComponent implements OnInit {
  LEVEL_ICONS = LEVEL_ICONS;
  ROUTES_DATA = ROUTES_DATA;
  total: number;
  notifications$: Observable<INotification[]> = this.notificationService
    .getNotifications({
      sort: '-_created',
      max_results: 4,
      where: { viewed: false },
    })
    .pipe(
      map((resp) => {
        this.total = resp._meta.total;
        return resp._items;
      })
    );
  notificationDetails$: Observable<Record<EventType, { title: string; description: string }>> =
    this.valuesService.getValues().pipe(pluck('notificationTypesMap'));

  constructor(
    private notificationService: NotificationService,
    private valuesService: ValuesService
  ) {}

  ngOnInit(): void {}
}
