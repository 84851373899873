export enum TFAType {
  NO = 0,
  APPLICATION = 1,
  SMS = 2,
}

export enum ProfileType {
  Main = 1,
  Admin = 2,
}

export enum ProfileStatusEnum {
  pending = 1,
  active,
  locked,
}
