<section class="dialog-content" mat-dialog-content>
  <ng-container *ngIf="data.content">
    <p class="text-center">{{ data.content | translate }}</p>
  </ng-container>
</section>

<section
  *ngIf="data.buttons"
  class="dialog-actions flex justify-content-center"
  mat-dialog-actions>
  <ng-container *ngFor="let btn of data.buttons">
    <kyc-button
      color="primary"
      [variant]="btn?.primary ? 'flat' : 'stroked'"
      (click)="confirm(btn?.primary)">
      <ng-container> {{ btn.name | translate }}</ng-container>
    </kyc-button>
  </ng-container>
</section>
