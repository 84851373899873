export enum TicketPriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export enum TicketDepartment {
  Support = 1,
  Technical = 2,
  Financial = 3,
  Reporting = 4,
}

export enum UserTicketDepartment {
  Support = 1,
  Technical = 2,
  Financial = 3,
  Reporting = 4,
}

export enum TicketState {
  WaitForUserResponse = 1,
  WaitForAdminResponse,
  Closed,
}
