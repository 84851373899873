import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserStatusEnum } from '../enums';

export const USER_STATUSES = [
  {
    id: UserStatusEnum.Active,
    value: _('user.status.active'),
  },
  {
    id: UserStatusEnum.Locked,
    value: _('user.status.locked'),
  },
  {
    id: UserStatusEnum.Pending,
    value: _('user.status.pending'),
  },
];
