import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ApplicantRiskLevelEnum } from '@modules/applicants/interfaces';

export const APPLICANT_RISK_LEVELS = [
  /*  {
    id: ApplicantRiskLevelEnum.Unknown,
    value: _('applicants.filter.riskLevel.unknown'),
  },*/
  {
    id: ApplicantRiskLevelEnum.Low,
    value: _('applicants.filter.riskLevel.low'),
  },
  {
    id: ApplicantRiskLevelEnum.Medium,
    value: _('applicants.filter.riskLevel.medium'),
  },
  {
    id: ApplicantRiskLevelEnum.High,
    value: _('applicants.filter.riskLevel.high'),
  },
];
