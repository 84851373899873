import { FormGroup, ValidatorFn, FormArray } from '@angular/forms';

export function DocUniquePropValidator(formArrayName: string) {
  return (formGroup: FormGroup): ValidatorFn => {
    const formArrayControl = formGroup.controls[formArrayName] as FormArray;
    const invalidControl = [];

    Object.keys(formGroup.controls).forEach((controlName) =>
      formArrayControl.controls.forEach(
        (control, idx) => control.value?.['key'] === controlName && invalidControl.push(control)
      )
    );

    if (!invalidControl.length) return;
    invalidControl.forEach((form) => form.controls['key'].setErrors({ notUnique: true }));
  };
}
