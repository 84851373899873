import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../components';

export interface ConfirmOptions {
  content?: string;
  buttons?: { name: string; primary?: boolean }[];
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public dialog: MatDialog) {}

  // Open confirm popup
  public openConfirmPopup(
    options: ConfirmOptions,
    width: string,
    answer: (res: any) => void
  ): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width,
      maxWidth: '90vw',
      data: options,
    });

    dialogRef.afterClosed().subscribe((result) => {
      answer(result);
    });
  }

  // Close confirm popup
  closeActivePopups() {
    this.dialog.closeAll();
  }

  /* How use in component
   */

  /*
  method() {
    const options = {
      header: 'Title',
      body: 'Description text',
      buttons: [{name: 'Yes'}, {name: 'No'}]
    };
    this.popupsService.openConfirmPopup(options, answer => {
      if (answer) {
        // if answer CONFIRM
      } else {
        // IF answer REJECT
      }
    });
  }
  */
}
