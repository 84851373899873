<kyc-button
  [class.opened]="menuState"
  [matMenuTriggerFor]="menu"
  variant="stroked"
  color="success"
  class="profile__menu-btn"
  (menuOpened)="menuState = !menuState"
  (menuClosed)="menuState = !menuState">
  <kyc-icon>person</kyc-icon>
  <kyc-icon class="show-more">expand_more</kyc-icon>
</kyc-button>
<mat-menu #menu="matMenu">
  <a
    *ngIf="!isAdminUser"
    mat-menu-item
    [routerLink]="userProfileLink"
    class="profile__menu-item">
    {{ 'common.header.menu.account' | translate }}
  </a>
  <button class="profile__menu-item" (click)="logOutUser()" mat-menu-item>
    {{ 'common.header.menu.logout' | translate }}
  </button>
</mat-menu>
