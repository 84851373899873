import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const USER_LOCK_STATUS = [
  {
    id: true,
    value: _('userLock.status.locked'),
  },
  {
    id: false,
    value: _('userLock.status.unlocked'),
  },
];
