export enum Role {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
  USER = 'USER',
}

export enum RoleId {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  SUPPORT = 3,
  USER = 4,
}

export enum UserType {
  USER = 'user',
  ADMIN = 'admin',
}
