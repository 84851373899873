import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FraudTypeEnum } from '../enums/fraud-type.enum';

export const FRAUD_TYPES = [
  {
    id: FraudTypeEnum.FraudSignalsOnlineDuplicate,
    value: _('fraud.signal.onlineDuplicate'),
  },
  {
    id: FraudTypeEnum.FraudSignalsSuspiciousWords,
    value: _('fraud.signal.suspiciousWords'),
  },
  {
    id: FraudTypeEnum.FraudSignalsIsIdentityDocument,
    value: _('fraud.signal.identityDocument'),
  },
  {
    id: FraudTypeEnum.FraudSignalsImageManipulation,
    value: _('fraud.signal.imageManipulation'),
  },
];
