import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ISidenav } from '@shared';
import { ROUTES_DATA } from './route-paths';

const {
  NEWS,
  SUPPORT,
  INVOICES,
  APP_USERS,
  FEEDBACKS,
  ADMIN_USERS,
  PRICE_SETTINGS,
  DASHBOARD,
  NOTIFICATIONS,
} = ROUTES_DATA.ADMIN.children;

export const ADMIN_MENU: ISidenav[] = [
  {
    path: DASHBOARD.url,
    title: _('sidenav.title.home'),
    icon: 'apps',
  },
  {
    path: ADMIN_USERS.url,
    title: _('sidenav.admin.title.admins'),
    icon: 'user-check',
  },
  {
    path: APP_USERS.url,
    title: _('sidenav.admin.title.users'),
    icon: 'user',
  },
  {
    path: INVOICES.url,
    title: _('sidenav.admin.title.invoices'),
    icon: 'file-text',
  },
  {
    path: PRICE_SETTINGS.url,
    title: _('sidenav.admin.title.priceSettings'),
    icon: 'file-text',
  },
  {
    path: SUPPORT.url,
    title: _('sidenav.admin.title.support'),
    icon: 'life-buoy',
  },
  {
    path: NEWS.url,
    title: _('sidenav.admin.title.news'),
    icon: 'cast',
  },
  {
    path: FEEDBACKS.url,
    title: _('sidenav.admin.title.feedbacks'),
    icon: 'message-circle',
  },
  {
    path: NOTIFICATIONS.url,
    title: _('sidenav.admin.title.notifications'),
    icon: 'notifications',
  },
];
