import { Directive, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from '../helpers';

export type SortDirection = 'asc' | 'desc' | '';

const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: '[kycSortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableDirective implements OnInit {
  sortParams = this.activatedRoute.snapshot.queryParamMap.get('sort');
  sortKey = this.sortParams?.replace('-', '');

  @Input() kycSortable: string = '';
  direction: SortDirection;
  @Output() sort = new EventEmitter<SortEvent>();

  constructor(readonly activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.direction =
      this.kycSortable === this.sortKey && !isNullOrUndefined(this.kycSortable)
        ? this.sortParams?.startsWith('-')
          ? 'desc'
          : 'asc'
        : '';
  }

  rotate() {
    if (this.kycSortable) {
      this.direction = rotate[this.direction];
      this.sort.emit({ column: this.kycSortable, direction: this.direction });
    }
  }
}
