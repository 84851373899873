import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { StepState } from '../enums/verification.enum';

export const STEP_STATE = [
  {
    id: StepState.passed,
    value: _('applicants.filter.status.verified'),
  },
  {
    id: StepState.rejected,
    value: _('applicants.filter.status.rejected'),
  },
  {
    id: StepState.waitForApprove,
    value: _('applicants.filter.status.waitForApprove'),
  },
  {
    id: StepState.pending,
    value: _('applicants.filter.status.inProgress'),
  },
];
