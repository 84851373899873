import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ISelectOption } from '../select';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { IStepForm } from '../../interfaces';
import { FieldTypeEnum } from '../../enums';
import { plainDeleteNullableValues } from '../../helpers';
import { CustomValidators } from '../../validation';

@Component({
  selector: 'kyc-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit {
  surveyForm: FormGroup;
  currentFormData: IStepForm;

  @Input() set formData(form: IStepForm) {
    this.currentFormData = form;
    this._buildForm();
  }
  @Input() verificationSide = true;
  @Input() customisableFields = false;

  @Output() onFormSubmit = new EventEmitter();

  FieldTypeEnum = FieldTypeEnum;

  constructor(readonly fb: FormBuilder) {}

  ngOnInit(): void {}

  private _buildForm() {
    const formControls = {};
    this.currentFormData.fields.forEach(
      (field) =>
        (formControls[field.placeholder] = [
          field.value ?? null,
          field.required ? Validators.required : null,
        ])
    );
    this.surveyForm = this.fb.group(
      this.customisableFields
        ? { ...formControls, custom_fields: this.fb.array([]) }
        : formControls,
      {
        validators: this.customisableFields && [
          CustomValidators.docUniquePropValidator('custom_fields'),
        ],
      }
    );
  }

  get customFields() {
    return this.surveyForm.controls['custom_fields'] as FormArray;
  }

  addCustomField() {
    const newField = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.customFields.push(newField);
  }

  deleteCustomField(fieldIndex: number) {
    this.customFields.removeAt(fieldIndex);
  }

  mapFieldsOptions = (options: string[]): ISelectOption<string, string>[] =>
    options.map((opt) => ({ id: opt, value: opt }));

  completeFormStep() {
    if (this.surveyForm.invalid) return;
    this.onFormSubmit.emit(plainDeleteNullableValues(this.surveyForm.value));
    this.surveyForm.reset();
  }
}
