import { UserType, TFAType } from '../enums';

export interface ILoginData {
  username: string;
  password: string;
  user_type: UserType;
}

export interface IRegisterData {
  username: string;
  password?: string;
  first_name: string;
  last_name: string;
  company_data?: {
    name: string;
  };
  company_id?: number;
}

export interface ILoginResponse {
  tfa_token: string;
  user_id: number;
  tfa_type?: TFAType;
}

export interface ILogin2FACode {
  otp?: string;
  sms_code?: string;
  user_id: number;
  user_type: string;
}
export interface ILogin2FARecoveryCode {
  recovery_code: string;
  user_id: number;
  user_type: string;
}

export interface IEnableTFA {
  tfa_token: string;
  tfa_type: TFAType;
  phone_number?: string;
  user_type: UserType;
}

export interface IEnableTFA_Response {
  tfa_link: string;
  totp_token: string;
  issuer: string;
}

export interface IConfirmTFA {
  tfa_token: string;
  tfa_type: TFAType;
  user_type: UserType;
  otp?: string;
  sms_code?: string;
}
export interface ILoginTFA_Response {
  role: number;
  token: string;
  _id: string;
}
