import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

type BtnColorType = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
type BtnVariant = 'flat' | 'raised' | 'icon' | 'stroked' | 'rounded';

@Component({
  selector: 'kyc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: BtnColorType = 'primary';
  @Input() variant: BtnVariant = 'flat';
  @Input() @HostBinding('attr.disabled') disabled = false;
}
