import { Pipe, PipeTransform } from '@angular/core';
import { IFraudCheck } from '@modules/applicants/interfaces';
import { FraudTypeEnum, CommonState } from '../enums/fraud-type.enum';
import { isNil } from '@shared';

interface MappedFraudCheck {
  type: FraudTypeEnum;
  state: CommonState;
  words?: string[];
  hostName?: string;
  url?: string;
}

@Pipe({
  name: 'fraudPipe',
})
export class FraudPipe implements PipeTransform {
  transform(fraud_list: IFraudCheck[]): MappedFraudCheck[] {
    const getFraudState = (type: FraudTypeEnum): CommonState => {
      const passed = fraud_list.find((f) => f.type === type)?.passed;
      if (isNil(passed)) return CommonState.notApplicable;
      return passed ? CommonState.passed : CommonState.cancelled;
    };

    return [
      {
        type: FraudTypeEnum.FraudSignalsImageManipulation,
        state: getFraudState(FraudTypeEnum.FraudSignalsImageManipulation),
      },
      {
        type: FraudTypeEnum.FraudSignalsIsIdentityDocument,
        state: getFraudState(FraudTypeEnum.FraudSignalsIsIdentityDocument),
      },
      {
        type: FraudTypeEnum.FraudSignalsOnlineDuplicate,
        state: getFraudState(FraudTypeEnum.FraudSignalsOnlineDuplicate),
        url: fraud_list.find((f) => f.type === FraudTypeEnum.EvidenceThumbnailUrl)?.text,
      },
      {
        type: FraudTypeEnum.FraudSignalsSuspiciousWords,
        state: getFraudState(FraudTypeEnum.FraudSignalsSuspiciousWords),
        words: fraud_list
          ?.filter(
            (f) =>
              f.type === FraudTypeEnum.EvidenceSuspiciousWord ||
              f.type === FraudTypeEnum.EvidenceInconclusiveSuspiciousWord
          )
          ?.map((f) => f.text),
      },
    ];
  }
}
