import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmOptions } from '../../services';

@Component({
  selector: 'kyc-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent {
  answer = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOptions
  ) {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.dialogRef.close(this.answer);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.answer);
  }

  confirm(bntResp: boolean = false) {
    this.answer = bntResp;
    this.dialogRef.close(this.answer);
  }
}
