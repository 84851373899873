export interface ISnackBarConf {
  title: string;
  message: string;
  icon: string;
}

export interface ISidenav {
  path?: string;
  title: string;
  icon?: string;
  order?: number;
  disabled?: boolean;
  children?: ISidenav[];
}

export interface IMenuItem {
  title: string;
  url?: string;
  hint?: string;
  type?: 'link' | 'action';
  action?: () => void;
}

export interface ITableHeader {
  title: string;
  sortKey?: string;
}
