import { ProfileType } from '../enums/auth.enum';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const PROFILE_TYPES = [
  {
    id: ProfileType.Main,
    value: _('auth.user.type.1'),
  },
  {
    id: ProfileType.Admin,
    value: _('auth.user.type.2'),
  },
];
