import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { delay, skip, tap } from 'rxjs/operators';

import { LocaleService } from '@core';
import {
  BaseHttpService,
  FEEDBACK_TYPES,
  TICKET_PRIORITIES,
  TICKET_DEPARTMENTS,
  ICountry,
  IResponse,
  ISelectOption,
  IValues,
  APPLICANT_STATUSES,
  APPLICANT_RISK_LEVELS,
  TFA_TYPES,
  FIELD_TYPES,
  USER_VERIFICATION_STEPS,
  SELFIE_TYPES,
  DOCUMENTS_CAPTURE_TYPES,
  DEFAULT_FORM_FIELDS,
  NOTIFICATION_LEVELS,
  EVENT_TYPES,
  EVENT_TYPE_DATA,
  DOCUMENT_TYPES,
  APPLICANT_ACTIONS,
  INVOICE_STATUSES,
  PROFILE_STATUSES,
  PROFILE_TYPES,
  USER_ROLES,
  PAYMENT_METHODS,
  USER_LOCK_STATUS,
  FULL_VERIFICATION_STEPS,
  FACE_SIMILARITY,
  SERVICE_TYPES,
  VERIFICATION_TYPES,
  ADDRESS_VERIFICATION_STEPS,
  ADDRESS_DOCUMENT_TYPE,
  ALL_DOCS_TYPE,
} from '@shared';
import { HttpClient } from '@angular/common/http';
import { USER_STATUSES } from '../../shared/constants/user-statuses';
import { STEP_STATE } from '../../shared/constants/step-state';
import { FRAUD_TYPES } from '../../shared/constants/fraud-types';
import { COMMON_STATE } from '../../shared/constants/common-state';
import { NEWS_TYPES } from '../../shared/constants/news-type';

@Injectable({
  providedIn: 'root',
})
export class ValuesService extends BaseHttpService {
  private values$ = new BehaviorSubject<IValues>(this.createValues());

  constructor(readonly httpClient: HttpClient, readonly localeService: LocaleService) {
    super('');
    this.getCountries().subscribe();
    this.localeService
      .getLocale()
      .pipe(skip(1), delay(1))
      .subscribe(() => {
        this.rebuildValues();
      });
  }

  getValues(): Observable<IValues> {
    return this.values$.asObservable();
  }

  getValuesValue(): IValues {
    return this.values$.getValue();
  }

  createValues() {
    return {
      ...this?.values$?.getValue(),
      feedbackTypes: FEEDBACK_TYPES.map((el) => this.processValueOption(el)),
      ticketPriorities: TICKET_PRIORITIES.map((el) => this.processValueOption(el)),
      ticketDepartments: TICKET_DEPARTMENTS.map((el) => this.processValueOption(el)),
      ticketDepartmentsMap: TICKET_DEPARTMENTS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      applicantStatuses: APPLICANT_STATUSES.map((el) => this.processValueOption(el)),
      applicantStatusesMap: APPLICANT_STATUSES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      stepStateMap: STEP_STATE.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      userRoles: USER_ROLES.map((el) => this.processValueOption(el)),
      userRolesMap: USER_ROLES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      serviceTypes: SERVICE_TYPES.map((el) => this.processValueOption(el)),
      serviceTypesMap: SERVICE_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      paymentMethods: PAYMENT_METHODS.map((el) => this.processValueOption(el)),
      paymentMethodsMap: PAYMENT_METHODS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      userLockedStatus: USER_LOCK_STATUS.map((el) => this.processValueOption(el)),
      applicantRiskLevels: APPLICANT_RISK_LEVELS.map((el) => this.processValueOption(el)),
      applicantRiskLevelsMap: APPLICANT_RISK_LEVELS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      tfaTypes: TFA_TYPES.map((el) => this.processValueOption(el)),
      fieldTypes: FIELD_TYPES.map((el) => this.processValueOption(el)),
      userVerificationSteps: USER_VERIFICATION_STEPS.map((el) => this.processValueOption(el)),
      addressVerificationSteps: ADDRESS_VERIFICATION_STEPS.map((el) => this.processValueOption(el)),
      fullVerificationStepsMap: FULL_VERIFICATION_STEPS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      verificationTypes: VERIFICATION_TYPES.map((el) => this.processValueOption(el)),
      verificationTypesMap: VERIFICATION_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      faceSimilarityMap: FACE_SIMILARITY.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      selfieTypes: SELFIE_TYPES.map((el) => this.processValueOption(el)),
      selfieTypesMap: SELFIE_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      userStatuses: USER_STATUSES.map((el) => this.processValueOption(el)),
      userStatusesMap: USER_STATUSES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      fileCapture: DOCUMENTS_CAPTURE_TYPES.map((el) => this.processValueOption(el)),
      documentTypes: DOCUMENT_TYPES.map((el) => this.processValueOption(el)),
      documentTypesMap: DOCUMENT_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      addressDocumentTypes: ADDRESS_DOCUMENT_TYPE.map((el) => this.processValueOption(el)),
      addressDocumentTypesMap: ADDRESS_DOCUMENT_TYPE.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      allDocumentsTypeMap: ALL_DOCS_TYPE.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      defaultFormFields: DEFAULT_FORM_FIELDS.map((el) => this.processValueOption(el)),
      teamMemberRoles: PROFILE_TYPES.map((el) => this.processValueOption(el)),
      teamMemberStatuses: PROFILE_STATUSES.map((el) => this.processValueOption(el)),
      applicantActions: APPLICANT_ACTIONS.map((el) => this.processValueOption(el)),
      applicantActionsMap: APPLICANT_ACTIONS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      invoiceStatuses: INVOICE_STATUSES.map((el) => this.processValueOption(el)),
      invoiceStatusesMap: INVOICE_STATUSES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      newsTypes: NEWS_TYPES.map((el) => this.processValueOption(el)),
      newsTypesMap: NEWS_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      notificationLevels: NOTIFICATION_LEVELS.map((el) => this.processValueOption(el)),
      notificationTypes: EVENT_TYPES.map((el) => this.processValueOption(el)),
      notificationTypesMap: EVENT_TYPE_DATA,
      fraudTypesMap: FRAUD_TYPES.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      commonStateMap: COMMON_STATE.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
    } as IValues;
  }

  private getCountries() {
    return this.get<IResponse<ICountry>>({ max_results: 500 }, 'country').pipe(
      tap((res) => {
        this.values$.next({
          ...this.createValues(),
          countries: res._items,
          countriesSelect: res._items?.map(({ name, _id }) => ({ id: _id, value: name })),
          countriesMap: res._items.reduce((acc, el) => {
            acc[el._id] = el;
            return acc;
          }, {}),
        });
      })
    );
  }

  rebuildValues() {
    this.values$.next({ ...this.createValues() });
  }

  processValue(key: string) {
    return this.localeService.getInstant(key);
  }

  processValueOption(el: ISelectOption<any>) {
    return {
      ...el,
      value: this.processValue(el.value),
    };
  }
}
