<mat-form-field [class]="classes" floatLabel="always" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    [formControl]="control"
    matInput
    [matDatepickerFilter]="myFilter"
    (focus)="onFocus()"
    (keydown.enter)="onEnterKeyDown($event)"
    [matDatepicker]="picker"
    [placeholder]="placeholder | translate"
    [max]="maxDate"
    [min]="minDate"
    [readonly]="readonly"
    (click)="readonly ? picker.open() : $event.preventDefault()"
    [ngClass]="{ 'cursor-pointer': readonly }" />
  <mat-icon matPrefix>date_range</mat-icon>
  <mat-datepicker-toggle
    *ngIf="this.control.value"
    matSuffix
    (click)="onClear()">
    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon>expand_more</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker [startAt]="startAt"></mat-datepicker>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('invalidDate')">
    {{ 'form.errors.invalidDate' | translate }}
  </mat-error>
</mat-form-field>
