import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ISidenav } from '@shared';
import { ROUTES_DATA } from './route-paths';

const { HOME, APPLICANTS, ORDERS, NOTIFICATIONS, SUPPORT, SETTINGS, INTEGRATIONS } = ROUTES_DATA;

export const PRIVATE_MENU: ISidenav[] = [
  {
    path: HOME.url,
    title: _('sidenav.title.home'),
    icon: 'apps',
  },
  {
    path: APPLICANTS.url,
    title: _('sidenav.title.applicants'),
    icon: 'group',
  },
  {
    path: INTEGRATIONS.url,
    title: _('sidenav.title.integrations'),
    icon: 'integrations',
    children: [
      {
        path: INTEGRATIONS.children.VERIFICATIONS.url,
        title: _('sidenav.title.verifications'),
      },
      {
        path: INTEGRATIONS.children.CREATE.url,
        title: _('sidenav.title.newVerification'),
      },
    ],
  },
  {
    path: SETTINGS.url,
    title: _('sidenav.title.settings'),
    icon: 'settings',
    children: [
      {
        path: SETTINGS.children.PROFILE.url,
        title: _('sidenav.title.profile'),
      },
      {
        path: SETTINGS.children.ACCOUNT.url,
        title: _('sidenav.title.account'),
      },
      {
        path: SETTINGS.children.TEAM_MEMBERS.url,
        title: _('sidenav.title.teamMembers'),
      },
      {
        path: SETTINGS.children.BRANDING.url,
        title: _('sidenav.title.branding'),
      },
    ],
  },
  {
    path: ORDERS.url,
    title: _('sidenav.title.orders'),
    icon: 'list_alt',
    children: [
      {
        path: ORDERS.children.PLANS.url,
        title: _('sidenav.title.plans'),
      },
      {
        path: ORDERS.children.LIST.url,
        title: _('sidenav.title.orderList'),
      },
    ],
  },

  {
    path: NOTIFICATIONS.url,
    title: _('sidenav.title.notifications'),
    icon: 'notifications',
  },
  {
    path: SUPPORT.url,
    title: _('sidenav.title.support'),
    icon: 'life-buoy',
  },
];
