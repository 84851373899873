export enum EventType {
  ConfirmRegistration = 1,
  SuccessRegistration = 2,
  PasswordReset = 3,
  PasswordChanged = 4,
  UsernameChanged = 5,
  SuccessLogin = 6,
  FailedLogin = 7,
  ContactUs = 8,
  TicketCreated = 9,
  TicketClosed = 10,
  TicketResponded = 11,
  TicketReopened = 12,
  NewTicket = 13,
  CloseTicket = 14,
  NewNotification = 15,
  SendConfirmationSmsCode = 16,
  InvoiceCreated = 17,
  InvoiceCompleted = 18,
  InvoiceFailed = 19,
  UserServiceWillExpireSoon = 20,
  UserServiceFreezeWillEndSoon = 21,
  UserServiceFrozen = 22,
  UserServiceAutorenewed = 23,
  UserServiceExpired = 24,
  UnsuccessfulServiceActivation = 25,
  PaymentBankTransfer = 26,
  CreateVerificationSettings = 27,
  EmailConfirmation = 28,
  UploadFile = 29,
  SuccessSocialRegistration = 30,
  LowVerificationBalance = 31,
  EmptyVerificationBalance = 32,
  VerificationCompleted = 33,
}

export enum NotificationLevel {
  General = 1,
  Financial = 2,
  Tickets = 3,
  Applicants = 4,
}
