import { HttpClient, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';

import { DefaultQueryEncoder } from './default-query.encoder';
import { environment } from '@env';

export abstract class BaseHttpService {
  protected readonly baseUrl: string;
  protected readonly encoder: HttpParameterCodec;
  protected abstract readonly httpClient: HttpClient;

  constructor(path: string) {
    const pathUrl = path ? `/${path}` : '';
    this.baseUrl = `${environment.apiUrl}/api${pathUrl}`;
    this.encoder = new DefaultQueryEncoder();
  }

  get<TResponse>(params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.baseUrl}${path ? '/' : ''}${path}`;
    return this.httpClient.get<TResponse>(url, {
      ...config,
      params: new HttpParams({
        fromObject: this.stringifyParams(params),
        encoder: this.encoder,
      }),
    });
  }

  delete<TResponse>(path: string | number = '', params?: {}, body = {}) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.delete<TResponse>(url, {
      params: new HttpParams({
        fromObject: this.stringifyParams(params),
        encoder: this.encoder,
      }),
      body,
    });
  }

  post<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.baseUrl}${path ? '/' : ''}${path}`;

    return this.httpClient.post<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: this.stringifyParams(params),
        encoder: this.encoder,
      }),
    });
  }

  put<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.put<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: this.stringifyParams(params),
        encoder: this.encoder,
      }),
    });
  }

  patch<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.baseUrl}${path ? '/' : ''}${path}`;

    return this.httpClient.patch<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: this.stringifyParams(params),
        encoder: this.encoder,
      }),
    });
  }

  request<TRequest, TResponse = TRequest>(request: HttpRequest<TRequest>) {
    return this.httpClient.request<TResponse>({
      params: new HttpParams({
        fromObject: this.stringifyParams(request.params as {}),
        encoder: this.encoder,
      }),
    } as HttpRequest<unknown>);
  }

  private stringifyParams(params: {}) {
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          params[key] = typeof params[key] === 'string' ? params[key] : JSON.stringify(params[key]);
        }
      }
      return params;
    }
    return {};
  }
}
