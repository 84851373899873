import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalService, ConfirmOptions, ROUTES_DATA } from '@shared';
import { AuthService } from '@core';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'kyc-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit {
  @Input() isAdminUser;
  menuState = false;
  userProfileLink = ROUTES_DATA.SETTINGS.children.ACCOUNT.url;

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  logOutUser() {
    const options: ConfirmOptions = {
      content: _('common.modal.logout.title'),
      buttons: [
        { name: _('common.buttons.confirm'), primary: true },
        { name: _('common.buttons.cancel') },
      ],
    };
    this.modalService.openConfirmPopup(options, '400px', (answer) => {
      if (answer) {
        this.authService.signOut();
        this.router.navigate([ROUTES_DATA.AUTH.children.SIGN_IN.url]);
      }
    });
  }
}
