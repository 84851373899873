import { NewsType } from '../enums/news-type.enum';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const NEWS_TYPES = [
  {
    id: NewsType.ProductUpdates,
    value: _('news.types.ProductUpdates'),
  },
  {
    id: NewsType.GuidesTutorials,
    value: _('news.types.GuidesTutorials'),
  },
  {
    id: NewsType.RegulatoryUpdates,
    value: _('news.types.RegulatoryUpdates'),
  },
  {
    id: NewsType.SecurityTips,
    value: _('news.types.SecurityTips'),
  },
  {
    id: NewsType.IndustryNews,
    value: _('news.types.IndustryNews'),
  },
  {
    id: NewsType.CustomerSuccessStories,
    value: _('news.types.CustomerSuccessStories'),
  },
  {
    id: NewsType.Announcements,
    value: _('news.types.Announcements'),
  },
  {
    id: NewsType.FAQsTroubleshooting,
    value: _('news.types.FAQsTroubleshooting'),
  },
  {
    id: NewsType.PartnershipsIntegrations,
    value: _('news.types.PartnershipsIntegrations'),
  },
  {
    id: NewsType.Tips,
    value: _('news.types.Tips'),
  },
  {
    id: NewsType.Other,
    value: _('news.types.Other'),
  },
];
