import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PaymentMethod } from '../enums';

export const PAYMENT_METHODS = [
  {
    id: PaymentMethod.Paypal,
    value: _('payment.method.paypal'),
  },
  {
    id: PaymentMethod.Interkassa,
    value: _('payment.method.interkassa'),
  },
  {
    id: PaymentMethod.BankTransfer,
    value: _('payment.method.bankTransfer'),
  },
  {
    id: PaymentMethod.Stripe,
    value: _('payment.method.stripe'),
  },
];
