export enum FileType {
  Unknown,
  Image,
  Video,
  Music,
  Archive,
  Document,
}

export enum ImageAnalysisMethod {
  ErrorLevelAnalysis = 1,
  NoiseAnalysis = 2,
  Exif = 3,
}

export enum PresetType {
  today,
  week,
  month,
  year,
}
