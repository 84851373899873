import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InvoiceState } from '../enums/orders.enum';

export const INVOICE_STATUSES = [
  {
    id: InvoiceState.Paid,
    value: _('invoice.status.paid'),
  },
  {
    id: InvoiceState.Declined,
    value: _('invoice.status.declined'),
  },
  {
    id: InvoiceState.Archived,
    value: _('invoice.status.archived'),
  },
  {
    id: InvoiceState.Pending,
    value: _('invoice.status.pending'),
  },
  {
    id: InvoiceState.WaitForConfirm,
    value: _('invoice.status.waitForConfirm'),
  },
];
