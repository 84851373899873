import { IconComponent } from './icon';
import { ChipsComponent } from './chips';
import { LabelComponent } from './label';
import { InputComponent } from './input';
import { SelectComponent } from './select';
import { ButtonComponent } from './button';
import { ConfirmComponent } from './confirm';
import { TooltipDirective } from './tooltip';
import { TextareaComponent } from './textarea';
import { CheckboxComponent } from './checkbox';
import { SnackBarComponent } from './snack-bar';
import { PaginatorComponent } from './paginator';
import { UserAvatarComponent } from './user-avatar';
import { DatePickerComponent } from './date-picker';
import { FileUploadComponent } from './file-upload';
import { TextEditorComponent } from './text-editor';
import { DynamicFormComponent } from './dynamic-form';
import { ImageCropperComponent } from './image-cropper';
import { LangSwitcherComponent } from './lang-switcher';
import { AttachmentItemComponent } from './attachment-item';
import { TicketShotInfoComponent } from './ticket-shot-info';
import { ProgressSpinnerComponent } from './progress-spinner';
import { EmptySearchPageComponent } from './empty-search-page';
import { TableListWrapperComponent } from './table-list-wrapper';
import { ControlFileUploadComponent } from './control-file-upload';

export * from './icon';
export * from './label';
export * from './input';
export * from './chips';
export * from './button';
export * from './select';
export * from './confirm';
export * from './tooltip';
export * from './checkbox';
export * from './textarea';
export * from './paginator';
export * from './snack-bar';
export * from './date-picker';
export * from './user-avatar';
export * from './file-upload';
export * from './text-editor';
export * from './dynamic-form';
export * from './lang-switcher';
export * from './image-cropper';
export * from './attachment-item';
export * from './ticket-shot-info';
export * from './progress-spinner';
export * from './empty-search-page';
export * from './table-list-wrapper';
export * from './control-file-upload';

export const SHARED_COMPONENTS = [
  IconComponent,
  InputComponent,
  LabelComponent,
  ChipsComponent,
  SelectComponent,
  ButtonComponent,
  TooltipDirective,
  ConfirmComponent,
  CheckboxComponent,
  SnackBarComponent,
  TextareaComponent,
  PaginatorComponent,
  UserAvatarComponent,
  DatePickerComponent,
  FileUploadComponent,
  TextEditorComponent,
  DynamicFormComponent,
  LangSwitcherComponent,
  ImageCropperComponent,
  TicketShotInfoComponent,
  AttachmentItemComponent,
  ProgressSpinnerComponent,
  EmptySearchPageComponent,
  TableListWrapperComponent,
  ControlFileUploadComponent,
];
