import {
  ICommonProps,
  LanguageEnum,
  IVerification,
  VerificationEnum,
  IProfile,
  DocumentState,
  DocumentTypeEnum,
  DocumentCheck,
  SelfieTypeEnum,
  FormState,
  UploadedBy,
} from '@shared';
import { IStep } from '@modules/applicants/pages';
import { FraudTypeEnum } from '../../../shared/enums/fraud-type.enum';

export enum ApplicantStateEnum {
  InProgress = 1,
  Verified = 2,
  Rejected = 3,
  Blocked = 4,
  WaitForApprove = 5,
}

export enum ApplicantRiskLevelEnum {
  Unknown = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum ActionType {
  WrongEmailConfirmationCode = 1,
  EmailVerified = 2,
  PhoneVerificationRequested = 3,
  WrongPhoneConfirmationCode = 4,
  PhoneVerified = 5,
  FromStepPassed = 6,
  FromPassed = 7,
  FromStepVerified = 8,
  FromVerified = 9,
  FromStepRejected = 10,
  SelfiePassed = 11,
  SelfieRejected = 12,
  SelfieVerified = 13,
  DocumentPassed = 14,
  DocumentSideRejected = 15,
  DocumentSideVerified = 16,
  DocumentVerified = 17,
  VerificationCompleted = 18,
  ApplicantVerified = 19,
}

export interface IApplicant extends ICommonProps {
  state: ApplicantStateEnum;
  notes: string;
  risks?: IRisk[];
  email: string;
  phone_code: string;
  phone_number: string;
  language: LanguageEnum;
  session_ids: number[];
  passed_steps: VerificationEnum[];
  rejected_steps: IRejectedStep[];
  form_steps: IFormSteps[];
  add_data: Partial<IApplicantAddData>;
  contacts_id: number;
  verification_map_id: number;
  verification_map?: IVerification;
  documents?: IDocument[];
  link: string;
  identifier: string;
  full_phone: string;
  country_id: number;
  company_id: number;
  applicant_profile?: IApplicantProfile;
  tags: string[];
  blocklisted_email: boolean;
  blocklisted_phone: boolean;
  suspicious_email: boolean;
  review_count: number;
  selfie_session_id?: string;
  viewSteps?: IStep[];
  viewed: boolean;
  address_form_state?: FormState;
}

export interface IApplicantAddData {
  description: string;
}

export interface INotes extends ICommonProps {
  text: string;
  applicant_id: number;
  user_id: number;
  company_id: number;
  user?: IProfile;
}

export interface IRisk extends ICommonProps {
  company_id: number;
  applicant_id: number;
  user_id: number;
  description: string;
  level: ApplicantRiskLevelEnum;
  user?: IProfile;
}

export interface IDocument extends ICommonProps {
  company_id: number;
  applicant_id: number;
  check_result: DocumentCheck;
  file_name: string;
  form_step: boolean;
  side: string;
  extract_confidence: DocumentCheck;
  similarity: DocumentCheck;
  photoshop: DocumentCheck;
  uploaded_by: UploadedBy;
  selfie_type: SelfieTypeEnum;
  state: DocumentState;
  step: VerificationEnum;
  type: DocumentTypeEnum;
  document_photo: boolean;
  attributes?: {
    ext: string;
    public_name: string;
    resolution: string;
    size: number;
  };
  add_data?: {
    [key: string]: string;
  };
  fraud_checks?: IFraudCheck[];
}

export interface IRejectedStep {
  step: VerificationEnum;
  comment: string;
  form_step?: number;
  side?: string;
}

export interface IFormSteps {
  state: FormState;
  step: number;
}

export interface IApplicantProfile extends ICommonProps {
  first_name: string;
  middle_name: string;
  last_name: string;
  birthday: string;
  country_of_birth_id: string;
  state_of_birth: string;
  place_of_birth: string;
  nationality: string;
  gender: number;
  tax_number: string;
  add_data?: {
    [key: string]: string;
  };
  form: IApplicantFilledForm[];
  address_form: IApplicantFilledForm;
  company_id: number;
}

export interface IApplicantFilledForm {
  placeholder?: string;
  id?: number;
  fields: {
    field: string;
    value: string;
  }[];
}

export interface IAction extends ICommonProps {
  action_type: ActionType;
  session_id: number;
  applicant_id: number;
  company_id: number;
  by_admin: boolean;
  session?: {
    ip: string;
    country_id: number;
    platform: string;
    last_auth: string;
    parsedPlatform?: any;
  };
}

export interface IStepApprove {
  applicant_id: number;
  step: VerificationEnum;
  side?: string;
  form_step?: number;
}

export enum DocumentAction {
  edit,
  errorLevel,
  noise,
}

export interface IImageAnalysis {
  image: string;
  exif: {
    tags: Object;
    attentive_tags: string[];
    datetime_differ: boolean;
  };
}

export interface IFraudCheck {
  passed: boolean;
  text: string;
  type: FraudTypeEnum;
}
