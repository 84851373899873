import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GlobalObjectService } from './global-object.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    private GOS: GlobalObjectService,
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  public postRedirect(params: Object, action: string) {
    if (this.GOS.isPlatformBrowser()) {
      const form = this.document.createElement('form');
      form.method = 'POST';
      form.target = '_top';
      form.action = action;

      for (let prop in params) {
        const input = this.document.createElement('input');
        input.type = 'hidden';
        input.name = prop;
        input.value = params[prop];
        form.append(input);
      }

      this.document.body.appendChild(form);
      form.submit();
    }
  }
}
