import { Component } from '@angular/core';
import { LocaleService } from '@core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(readonly localeService: LocaleService, readonly router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // change url for multi language /companies => /de/companies
        this.localeService.updateLocaleUrl(event.url);
      }
    });
  }
}
