import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PresetType } from '../enums/common.enum';

export const PRESET_OPTIONS = [
  {
    name: _('global.today'),
    type: PresetType.today,
  },
  {
    name: _('global.week'),
    type: PresetType.week,
  },
  {
    name: _('global.month'),
    type: PresetType.month,
  },
  {
    name: _('global.year'),
    type: PresetType.year,
  },
];
