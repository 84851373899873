import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(
    value: any,
    onlyCode = false,
    currencyCode: string = 'UAH',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol-narrow',
    digitsInfo: string = '0.2-2'
  ) {
    return onlyCode
      ? getCurrencySymbol(value, 'narrow', 'uk')
      : new CurrencyPipe('uk', 'symbol-narrow').transform(
          value,
          currencyCode,
          display,
          digitsInfo,
          'uk'
        );
  }
}
