import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kyc-control-file-upload',
  templateUrl: './control-file-upload.component.html',
  styleUrls: ['./control-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ControlFileUploadComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlFileUploadComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() withPreview = false;
  @Output() onfileRemove = new EventEmitter();
  onChange: Function;
  file: File | null = null;
  imageURL: string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  @ViewChild('fileInput') fileInputVar: ElementRef;

  constructor(private host: ElementRef<HTMLInputElement>, private cd: ChangeDetectorRef) {}

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
    this.imageURL = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    // TODO add type check
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
        this.cd.markForCheck();
      };
      reader.readAsDataURL(file);
    }
  }

  registerOnTouched(fn: Function) {}

  onPreviewRemove() {
    this.writeValue(null);
    this.fileInputVar.nativeElement.value = '';
    this.onfileRemove.emit();
  }
}
