import {
  VerificationType,
  SelfieTypeEnum,
  VerificationEnum,
  FieldTypeEnum,
  DocumentTypeEnum,
  DocumentCapturedEnum,
} from '../enums';
import { IStepForm } from '../interfaces';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const VERIFICATION_TEMPLATES = {
  [VerificationType.Address]: {
    selfie: SelfieTypeEnum.NoSelfie,
    documents: [DocumentTypeEnum.Passport, DocumentTypeEnum.DriversLicense],
    documents_captured: DocumentCapturedEnum.FileUpload,
    steps: [
      VerificationEnum.ConfirmEmail,
      VerificationEnum.Documents,
      VerificationEnum.AddressDocuments,
      VerificationEnum.AddressForm,
    ],
  },
};

export const ADDRESS_FORM: IStepForm = {
  id: 1,
  placeholder: _('verification.templates.address.title'),
  fields: [
    {
      placeholder: 'First Name',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'Last Name',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'Country',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'City',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'Street',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'Building Number',
      type: FieldTypeEnum.Str,
      required: true,
    },
    {
      placeholder: 'Zip Code',
      type: FieldTypeEnum.Int,
      required: true,
    },
  ],
};
