<div #sidebar class="sidebar">
  <ng-container *ngFor="let route of routes$ | async">
    <mat-expansion-panel
      expandOnActiveLink
      *ngIf="route.children?.length"
      class="mat-elevation-z0">
      <mat-expansion-panel-header class="sidebar__item" matRipple>
        <kyc-icon
          *ngIf="route.icon"
          class="sidebar__item-icon"
          [icon]="route.icon"></kyc-icon>
        {{ route.title | translate }}
      </mat-expansion-panel-header>

      <ng-container *ngFor="let child of route.children">
        <a
          [routerLink]="child.path ?? './'"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="active"
          class="sidebar__item sidebar__item-inner"
          [attr.data-route]="child.path"
          (click)="toggleNav.emit()"
          matRipple
          >{{ child.title | translate }}</a
        >
      </ng-container>
    </mat-expansion-panel>

    <a
      *ngIf="!route.children?.length"
      [routerLink]="route.path ?? './'"
      [routerLinkActiveOptions]="{ exact: route.path === '' }"
      routerLinkActive="active"
      class="sidebar__item"
      [attr.data-route]="route.path"
      (click)="toggleNav.emit()"
      matRipple>
      <kyc-icon
        *ngIf="route.icon"
        class="sidebar__item-icon"
        [icon]="route.icon"></kyc-icon>
      {{ route.title | translate }}</a
    >
  </ng-container>
</div>
