<mat-form-field
  [ngClass]="classes"
  [ngClass]="{
    'without-label': !label,
    'has-hint': hint,
    'has-prefix': prefix,
    'with-field-state': showFieldState
  }"
  [floatLabel]="floatLabel"
  [appearance]="appearance"
  [@.disabled]="true">
  <mat-label>{{ label }}<span *ngIf="required">*</span></mat-label>
  <input
    #input
    [autocomplete]="autocomplete"
    [formControl]="control"
    [type]="inputType || 'text'"
    matInput
    [placeholder]="placeholder"
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [maxlength]="maxLength" />
  <mat-hint *ngIf="hint" align="start">{{ hint | translate }}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('unique')">
    {{ 'form.errors.unique' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error">
      {{
        'form.errors.min-length'
          | translate: { minLength: error.requiredLength }
      }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      {{
        'form.errors.max-length'
          | translate: { maxLength: error.requiredLength }
      }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('integer')">
    {{ 'form.errors.integer' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('number')">
    {{ 'form.errors.number' | translate }}
  </mat-error>
  <mat-error
    *ngIf="control.hasError('email') || control.hasError('emailFormat')">
    {{ 'form.errors.email' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('macAddress')">
    {{ 'form.errors.macAddress' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanum')">
    {{ 'form.errors.alphanum' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('endsWithPeriod')">
    {{ 'form.errors.ends-with-period' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('specialChars')">
    {{ 'form.errors.special-chars' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanumWithSymbols')">
    {{ 'form.errors.alphanum-with-symbol' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('confirmedValidator')">
    {{ 'form.errors.confirmedValidator' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('notConfirmedValidator')">
    {{ 'form.errors.notConfirmedValidator' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('error.username_not_unique')">
    {{ 'error.username_not_unique' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('error.invite_username_already_exists')">
    {{ 'error.invite_username_already_exists' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('phoneFormat')">
    {{ 'form.errors.phoneFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('domainFormat')">
    {{ 'form.errors.domainFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('subdomainFormat')">
    {{ 'form.errors.subdomainFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('ipFormat')">
    {{ 'form.errors.ipFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('ipv4Format')">
    {{ 'form.errors.ipv4Format' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('ipv4PoolFormat')">
    {{ 'form.errors.ipv4PoolFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('ipv6Format')">
    {{ 'form.errors.ipv6Format' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('greaterThan')">
    {{ 'form.errors.greaterThan' | translate: control.getError('greaterThan') }}
  </mat-error>
  <mat-error *ngIf="control.hasError('greaterThanOrEqual')">
    {{
      'form.errors.greaterThanOrEqual'
        | translate: control.getError('greaterThanOrEqual')
    }}
  </mat-error>
  <mat-error *ngIf="control.hasError('passwordFormat')">
    {{ 'form.errors.passwordFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('lessThan')">
    {{ 'form.errors.lessThan' | translate: control.getError('lessThan') }}
  </mat-error>
  <mat-error *ngIf="control.hasError('datanodeStep')">
    {{
      'form.errors.datanodeStep' | translate: control.getError('datanodeStep')
    }}
  </mat-error>
  <mat-error *ngIf="control.hasError('suitableConfigValidator')">
    {{
      'form.errors.suitableConfigValidator'
        | translate: control.getError('suitableConfigValidator')
    }}
  </mat-error>
  <mat-error *ngIf="control.hasError('doubleFields')">
    {{ 'form.errors.doubleFields' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('notUnique')">
    {{ 'form.errors.notUnique' | translate }}
  </mat-error>

  <span *ngIf="prefix" matPrefix>
    <ng-content select="[prefix]"></ng-content>
  </span>
  <span matSuffix>
    <ng-container *ngIf="inputType === 'password'">
      <button
        class="input__eye-btn"
        [ngClass]="{
          'input__eye-btn--active': input.type === 'password'
        }"
        mat-icon-button
        type="button"
        (click)="input.type = input.type === 'password' ? 'text' : 'password'">
        <span *ngIf="input.type === 'password'" class="material-icons-outlined">
          visibility
        </span>
        <span *ngIf="input.type === 'text'" class="material-icons-outlined">
          visibility_off
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="inputType === 'number' && numberUpDown">
      <div class="number-up-down">
        <button
          type="button"
          (click)="incrementValue()"
          class="material-icons-outlined number-up-down-btn">
          arrow_drop_up
        </button>
        <button
          type="button"
          (click)="decrementValue()"
          class="material-icons-outlined number-up-down-btn">
          arrow_drop_down
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="showFieldState">
      <kyc-icon
        class="field-state"
        *ngIf="control.touched"
        [class]="control.invalid ? 'invalid' : 'valid'"
        >{{ control.invalid ? 'close' : 'done' }}</kyc-icon
      >
    </ng-container>
  </span>
</mat-form-field>
