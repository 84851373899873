<form
  *ngIf="surveyForm"
  [formGroup]="surveyForm"
  (ngSubmit)="completeFormStep()"
  class="survey-form">
  <h4 *ngIf="verificationSide && currentFormData.placeholder">
    {{ currentFormData.placeholder | translate }}
  </h4>
  <ng-container *ngFor="let field of currentFormData.fields">
    <kyc-input
      *ngIf="field.type === FieldTypeEnum.Str"
      [label]="field.placeholder"
      [placeholder]="field.placeholder"
      [formControlName]="field.placeholder"></kyc-input>
    <kyc-input
      *ngIf="
        field.type === FieldTypeEnum.Int || field.type === FieldTypeEnum.Float
      "
      inputType="number"
      [label]="field.placeholder"
      [placeholder]="field.placeholder"
      [formControlName]="field.placeholder"></kyc-input>
    <kyc-checkbox
      *ngIf="field.type === FieldTypeEnum.Bool"
      [formControlName]="field.placeholder"
      >{{ field.placeholder }}</kyc-checkbox
    >
    <kyc-date-picker
      *ngIf="field.type === FieldTypeEnum.DateTime"
      [label]="field.placeholder"
      [placeholder]="field.placeholder"
      [formControlName]="field.placeholder"></kyc-date-picker>
    <kyc-select
      *ngIf="field.type === FieldTypeEnum.Select"
      [optional]="!field.required"
      [label]="field.placeholder"
      [placeholder]="field.placeholder"
      [formControlName]="field.placeholder"
      [options]="mapFieldsOptions(field.options)"></kyc-select>
    <kyc-control-file-upload
      *ngIf="field.type === FieldTypeEnum.File && verificationSide"
      [formControlName]="field.placeholder"
      [label]="field.placeholder"></kyc-control-file-upload>
  </ng-container>
  <ng-container *ngIf="customisableFields" formArrayName="custom_fields">
    <div class="separator"></div>
    <ng-container
      *ngFor="let customField of customFields.controls; let i = index"
      [formGroupName]="i">
      <div class="survey-form__custom">
        <kyc-input
          [label]="'dynamic.field.key' | translate"
          [placeholder]="'dynamic.field.key' | translate"
          formControlName="key"></kyc-input>
        <kyc-input
          [label]="'dynamic.field.value' | translate"
          [placeholder]="'dynamic.field.value' | translate"
          formControlName="value"></kyc-input>
        <kyc-button variant="icon" (click)="deleteCustomField(i)"
          ><kyc-icon>cancel</kyc-icon></kyc-button
        >
      </div>
    </ng-container>

    <kyc-button fullwidth (click)="addCustomField()" variant="stroked">
      <kyc-icon>add</kyc-icon>
      {{ 'global.addNewFiled' | translate }}
    </kyc-button>
  </ng-container>

  <kyc-button
    [class.survey-form__action]="verificationSide"
    [attr.fullWidth]="!verificationSide"
    type="submit"
    [disabled]="surveyForm.invalid">
    {{ (verificationSide ? 'global.continue' : 'global.confirm') | translate }}
    <kyc-icon *ngIf="verificationSide">chevron_right</kyc-icon>
  </kyc-button>
</form>
