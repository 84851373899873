import { FieldTypeEnum, VerificationEnum, VerificationType } from '../enums';
import { ISelectOption } from '../components';

export interface IVerificationForm {
  _id?: number;
  name: string;
  description: string;
  type: VerificationType;
  auto_form?: boolean;
  steps: {
    type: VerificationEnum;
    data?: {
      option: any;
      variant?: any;
      auto?: boolean;
      required: boolean;
    };
  }[];
  forms: {
    placeholder: string;
    fields: {
      type: FieldTypeEnum | ISelectOption;
      placeholder: string;
      data: {
        required: boolean;
        placeholder: string;
        options: string[];
      };
    }[];
  }[];
}
