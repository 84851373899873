import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ProfileStatusEnum } from '../enums/auth.enum';

export const PROFILE_STATUSES = [
  {
    id: ProfileStatusEnum.active,
    value: _('auth.user.status.active'),
  },
  {
    id: ProfileStatusEnum.pending,
    value: _('auth.user.status.pending'),
  },
  {
    id: ProfileStatusEnum.locked,
    value: _('auth.user.status.locked'),
  },
];
