import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { AutoCleanupFeature, Features } from '../../decorators';
import { BaseControlComponent, FormStateDispatcher } from '../../abstracts';

import { ISelectOption, IGroupedSelectOptions } from './select-option.interface';

@Component({
  selector: 'kyc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
@Features([AutoCleanupFeature()])
export class SelectComponent<T> extends BaseControlComponent<T> implements OnInit {
  readonly destroyed$!: Observable<unknown>;
  @Input() readonly readonly: boolean = false;
  @Input() optional = true;
  @Input() label = '';
  @Input() multiple = false;
  @Input() options: ISelectOption[] = [];
  @Input() groupOptions: IGroupedSelectOptions[] = [];
  @Input() placeholder: string = _('pageElements.placeholder.select');
  @Input() optionalLabel;
  @Input() optionWithPrice = false;

  @Output() readonly changed = new EventEmitter<T | null>();

  @ViewChild(MatSelect, { static: true }) readonly select!: MatSelect;

  readonly control = new FormControl(null);

  @Input() displayFn!: (value: ISelectOption[], id: number) => number;
  @Input() valueFn = (option: ISelectOption | null) => option?.id;
  @Input() labelFn = (option: ISelectOption | null) => option?.value;
  @Input() compareFn = (
    option: ISelectOption | string,
    selected: ISelectOption | number | null
  ) => {
    if (selected === null) {
      return false;
    }

    if (typeof option === 'object' && typeof selected === 'object') {
      return option.id === selected.id;
    } else {
      return option === selected;
    }
  };

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.patchSelectOpen();

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.changed.emit(this.viewToModelParser(value) as T);
    });

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent | Event) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(index: number, option: ISelectOption) {
    return option?.id;
  }

  gropedSelectedOptionLabel = (value, group) => {
    return group
      ?.map((option) => option.options)
      ?.flat()
      ?.find((option) => option.id === value)?.value;
  };

  selectedOptionLabel = (value, options: ISelectOption[]) =>
    options?.find(({ id }) => value === id)?.value;

  private patchSelectOpen() {
    const open = this.select.open;

    this.select.open = () => {
      if (!this.readonly) {
        open.call(this.select);
      }
    };
  }
}
