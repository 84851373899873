import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { IAttachment, IOutPutData } from '@shared';

@Component({
  selector: 'kyc-attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentItemComponent implements OnInit {
  @Input() downloadMode = true;
  @Input() attachment: IAttachment;

  @Input() previewAttachment: IOutPutData;

  @Output() onAttachmentRemove = new EventEmitter<IAttachment>();
  @Output() onAttachmentUpload = new EventEmitter<IAttachment>();

  constructor() {}

  ngOnInit(): void {}

  parseFileSize = (size: number) =>
    size < 1_000_000 ? `${Math.ceil(size / 1_000)}KB` : `${(size / 1_000_000).toPrecision(2)}MB`;
}
