import { SafeHtmlPipe } from './safe-html.pipe';
import { EnumeratePipe } from './enumerate.pipe';
import { WherePipe } from './where.pipe';
import { EncodeURLPipe } from './encode-url.pipe';
import { FlattenPipe } from './flatten.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { FileSizePipe } from './file-size.pipe';
import { AdminRolesPipe } from './admin-roles.pipe';
import { FraudPipe } from './fraud.pipe';

export const SHARED_PIPES = [
  SafeHtmlPipe,
  EnumeratePipe,
  WherePipe,
  EncodeURLPipe,
  FlattenPipe,
  SafeUrlPipe,
  CurrencySymbolPipe,
  FileSizePipe,
  AdminRolesPipe,
  FraudPipe,
];
