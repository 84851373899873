import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TFAType } from '../enums';

export const TFA_TYPES = [
  {
    id: TFAType.APPLICATION,
    value: _('auth.twoFA.configs.type.application'),
  },
  {
    id: TFAType.SMS,
    value: _('auth.twoFA.configs.type.sms'),
  },
];
