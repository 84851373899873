import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import {
  ISupportTicket,
  getDifferanceBetweenDateAndNow,
  TicketState,
  getUserFullName,
  getCurrentUserAbbreviation,
  IProfile,
} from '@shared';

@Component({
  selector: 'kyc-ticket-shot-info',
  templateUrl: './ticket-shot-info.component.html',
  styleUrls: ['./ticket-shot-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketShotInfoComponent implements OnInit {
  userAbbreviation = 'un';
  TicketState = TicketState;
  ticketInfo: ISupportTicket;
  lastResponseFrom: string;
  userLogo: string;

  @Input() set ticket(ticket: ISupportTicket) {
    this.ticketInfo = ticket;
    const owner: Partial<IProfile> =
      ticket.state === TicketState.WaitForUserResponse ? ticket.admin : ticket.user;
    this.lastResponseFrom = owner && getUserFullName(owner);
    this.userAbbreviation = owner && getCurrentUserAbbreviation(owner);
  }

  getDatesFromLastResponse = () =>
    getDifferanceBetweenDateAndNow(this.ticketInfo.last_message_date) || 0;

  constructor() {}

  ngOnInit(): void {}
}
