import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { VerificationEnum } from '../enums';

export const VERIFICATION_PHOTO_REJECT = [
  {
    id: 'fakePhoto',
    title: _('verification.reject.photo.fakePhoto'),
    description: _('verification.reject.photo.fakePhoto.description'),
  },
  {
    id: 'lowQualityImage',
    title: _('verification.reject.photo.lowQualityImage'),
    description: _('verification.reject.photo.lowQualityImage.description'),
  },
  {
    id: 'inappropriateContent',
    title: _('verification.reject.photo.inappropriateContent'),
    description: _('verification.reject.photo.inappropriateContent.description'),
  },
  {
    id: 'unidentifiableFace',
    title: _('verification.reject.photo.unidentifiableFace'),
    description: _('verification.reject.photo.unidentifiableFace.description'),
  },
  {
    id: 'multiplePeoplePhoto',
    title: _('verification.reject.photo.multiplePeoplePhoto'),
    description: _('verification.reject.photo.multiplePeoplePhoto.description'),
  },
  {
    id: 'obstructedFace',
    title: _('verification.reject.photo.obstructedFace'),
    description: _('verification.reject.photo.obstructedFace.description'),
  },
  {
    id: 'outdatedPhoto',
    title: _('verification.reject.photo.outdatedPhoto'),
    description: _('verification.reject.photo.outdatedPhoto.description'),
  },
  {
    id: 'nonHumanObject',
    title: _('verification.reject.photo.nonHumanObject'),
    description: _('verification.reject.photo.nonHumanObject.description'),
  },
  {
    id: 'distortedImage',
    title: _('verification.reject.photo.distortedImage'),
    description: _('verification.reject.photo.distortedImage.description'),
  },
  {
    id: 'wearingSunglasses',
    title: _('verification.reject.photo.wearingSunglasses'),
    description: _('verification.reject.photo.wearingSunglasses.description'),
  },
];

export const VERIFICATION_DOC_REJECT = [
  {
    id: 'blurredDocument',
    title: _('verification.reject.document.blurredDocument'),
    description: _('verification.reject.document.blurredDocument.description'),
  },
  {
    id: 'expiredDocument',
    title: _('verification.reject.document.expiredDocument'),
    description: _('verification.reject.document.expiredDocument.description'),
  },
  {
    id: 'incompleteDocument',
    title: _('verification.reject.document.incompleteDocument'),
    description: _('verification.reject.document.incompleteDocument.description'),
  },
  {
    id: 'forgedDocument',
    title: _('verification.reject.document.forgedDocument'),
    description: _('verification.reject.document.forgedDocument.description'),
  },
  {
    id: 'lowQualityScan',
    title: _('verification.reject.document.lowQualityScan'),
    description: _('verification.reject.document.lowQualityScan.description'),
  },
  {
    id: 'incorrectDocument',
    title: _('verification.reject.document.incorrectDocument'),
    description: _('verification.reject.document.incorrectDocument.description'),
  },
  {
    id: 'nonStandardDocument',
    title: _('verification.reject.document.nonStandardDocument'),
    description: _('verification.reject.document.nonStandardDocument.description'),
  },
  {
    id: 'documentTooDark',
    title: _('verification.reject.document.documentTooDark'),
    description: _('verification.reject.document.documentTooDark.description'),
  },
  {
    id: 'unreadableText',
    title: _('verification.reject.document.unreadableText'),
    description: _('verification.reject.document.unreadableText.description'),
  },
];

export const VERIFICATION_FORM_REJECT = [
  {
    id: 'incompleteInformation',
    title: _('verification.reject.form.incompleteInformation'),
    description: _('verification.reject.form.incompleteInformation.description'),
  },
  {
    id: 'inconsistentInformation',
    title: _('verification.reject.form.inconsistentInformation'),
    description: _('verification.reject.form.inconsistentInformation.description'),
  },
  {
    id: 'incorrectFormat',
    title: _('verification.reject.form.incorrectFormat'),
    description: _('verification.reject.form.incorrectFormat.description'),
  },
  {
    id: 'unverifiableDetails',
    title: _('verification.reject.form.unverifiableDetails'),
    description: _('verification.reject.form.unverifiableDetails.description'),
  },
  {
    id: 'mismatchedInformation',
    title: _('verification.reject.form.mismatchedInformation'),
    description: _('verification.reject.form.mismatchedInformation.description'),
  },
  {
    id: 'outdatedInformation',
    title: _('verification.reject.form.outdatedInformation'),
    description: _('verification.reject.form.outdatedInformation.description'),
  },
  {
    id: 'nonMatchingIdentifiers',
    title: _('verification.reject.form.nonMatchingIdentifiers'),
    description: _('verification.reject.form.nonMatchingIdentifiers.description'),
  },
  {
    id: 'suspectedFraudulentInformation',
    title: _('verification.reject.form.suspectedFraudulentInformation'),
    description: _('verification.reject.form.suspectedFraudulentInformation.description'),
  },
  {
    id: 'sensitiveDataDisclosure',
    title: _('verification.reject.form.sensitiveDataDisclosure'),
    description: _('verification.reject.form.sensitiveDataDisclosure.description'),
  },
];

export const VERIFICATION_REJECT = {
  [VerificationEnum.Selfie]: VERIFICATION_PHOTO_REJECT,
  [VerificationEnum.Documents]: VERIFICATION_DOC_REJECT,
  [VerificationEnum.AddressDocuments]: VERIFICATION_DOC_REJECT,
  [VerificationEnum.AddressForm]: VERIFICATION_FORM_REJECT,
  [VerificationEnum.SurveyForm]: VERIFICATION_FORM_REJECT,
};
