<ul class="table">
  <li class="table__head" [style.grid-template-columns]="tableHeaderTemplate">
    <div
      *ngFor="let header of tableHeaders"
      class="table__head__item"
      [class.sort]="header.sortKey"
      [kycSortable]="header.sortKey"
      (sort)="onSortWrapper($event)">
      {{ header.title | translate }}
    </div>
  </li>
  <ng-content></ng-content>
</ul>
