import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { AutoCleanupFeature, Features } from '../../decorators';
import { FormStateDispatcher, BaseControlComponent } from '../../abstracts';

const DEFAULT_ROWS = 10;

@Component({
  selector: 'kyc-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
@Features([AutoCleanupFeature()])
export class TextareaComponent<T> extends BaseControlComponent<string> implements OnInit {
  readonly destroyed$!: Observable<unknown>;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() rows = DEFAULT_ROWS;
  @Input() trimValue = true;
  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value ? value.trim().replace(/\s{2,}/gm, ' ') : value;

    if (trimmedValue !== value && this.trimValue) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    this.changeDetector.markForCheck();
  }
}
