import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ApplicantStateEnum } from '@modules/applicants/interfaces';

export const APPLICANT_STATUSES = [
  {
    id: ApplicantStateEnum.InProgress,
    value: _('applicants.filter.status.inProgress'),
  },
  {
    id: ApplicantStateEnum.Verified,
    value: _('applicants.filter.status.verified'),
  },
  {
    id: ApplicantStateEnum.Rejected,
    value: _('applicants.filter.status.rejected'),
  },
  {
    id: ApplicantStateEnum.Blocked,
    value: _('applicants.filter.status.blocked'),
  },
  {
    id: ApplicantStateEnum.WaitForApprove,
    value: _('applicants.filter.status.waitForApprove'),
  },
];
