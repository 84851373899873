import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { AutoCleanupFeature, Features } from '../../decorators';
import { FormStateDispatcher, BaseControlComponent } from '../../abstracts';
import { ContentChange } from 'ngx-quill';

@Component({
  selector: 'kyc-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
@Features([AutoCleanupFeature()])
export class TextEditorComponent<T> extends BaseControlComponent<string> implements OnInit {
  readonly destroyed$!: Observable<unknown>;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() trimValue = true;
  @Input() hideToolbar = true;
  @Input() variables: any;

  readonly control = new FormControl(null);
  public editor: any;
  public modules: any;

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    private elem: ElementRef,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
    this.initEditorConfig(this.control);
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value ? value.trim().replace(/\s{2,}/gm, ' ') : value;

    if (trimmedValue !== value && this.trimValue) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    this.changeDetector.markForCheck();
  }

  initEditorConfig(formControl: any) {
    this.modules = {
      toolbar: {
        container: this.hideToolbar
          ? []
          : [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        handlers: {
          variables(variable: any) {
            const getQuill = (): any => this.quill;
            if (variable) {
              const formattedValue = ` <${variable}> `;
              const cursorPosition = getQuill().getSelection().index;
              getQuill().insertText(cursorPosition, formattedValue);

              formControl.setValue(this.quill.container.querySelector('.ql-editor').innerHTML);
              getQuill().setSelection(cursorPosition + formattedValue.length);
            }
          },
        },
      },
    };

    if (this.variables?.length) {
      this.modules.toolbar.container.push([{ variables: this.variables }]);
    }
  }

  onEditorCreated(quill: any) {
    this.elem.nativeElement.querySelector('.ql-editor').innerHTML = this.control.value?.trim();
    if (this.variables?.length) {
      this.createVariables();
    }
  }

  onContentChanged(event: ContentChange) {}

  createVariables() {
    const placeholderPickerItems = this.elem.nativeElement.querySelectorAll(
      '.ql-variables .ql-picker-item'
    );
    placeholderPickerItems.forEach((item: any) => (item.textContent = item.dataset.value));
    this.elem.nativeElement.querySelector('.ql-variables .ql-picker-label').innerHTML =
      'Variables &nbsp; &nbsp; &nbsp;' +
      this.elem.nativeElement.querySelector('.ql-variables .ql-picker-label').innerHTML;
  }
}
