export const ROUTES_DATA = {
  HOME: {
    url: '',
  },
  AUTH: {
    url: 'auth',
    children: {
      SIGN_IN: {
        path: 'login',
        url: 'auth/login',
      },
      SIGN_UP: {
        path: 'registration',
        url: 'auth/registration',
      },
      RESET_PASSWORD: {
        path: 'password-reset',
        url: 'auth/password-reset',
      },
      FORGOT_PASSWORD: {
        path: 'forgot-password',
        url: 'auth/forgot-password',
      },
      CONFIRM_USER: {
        path: 'confirm',
        url: 'auth/confirm',
      },
    },
  },
  ADMIN: {
    url: 'admin',
    children: {
      DASHBOARD: {
        path: 'dashboard',
        url: 'admin/dashboard',
      },
      AUTH: {
        url: 'admin/auth/login',
      },
      ADMIN_USERS: {
        path: 'admin-users',
        url: 'admin/admin-users',
      },
      APP_USERS: {
        path: 'app-users',
        url: 'admin/app-users',
      },
      FEEDBACKS: {
        path: 'feedbacks',
        url: 'admin/feedbacks',
        children: {
          NEW: {
            path: 'new',
            url: 'admin/feedbacks/new',
          },
          EDIT: {
            path: ':id',
            url: 'admin/feedbacks',
          },
        },
      },
      INVOICES: {
        path: 'invoices',
        url: 'admin/invoices',
      },
      NEWS: {
        path: 'news',
        url: 'admin/news',
        children: {
          NEW: {
            title: 'Create News',
            path: 'new',
            url: 'admin/news/new',
          },
          EDIT: {
            title: 'Edit News',
            path: ':id',
            url: 'admin/news',
          },
        },
      },
      PRICE_SETTINGS: {
        path: 'price-settings',
        url: 'admin/price-settings',
      },
      SUPPORT: {
        path: 'support',
        url: 'admin/support',
      },
      NOTIFICATIONS: {
        path: 'notifications',
        url: 'admin/notifications',
      },
    },
  },
  APPLICANTS: {
    url: 'applicants',
    children: {
      LIST: {
        path: 'list',
        url: 'applicants/list',
      },
      DETAILS: {
        path: ':id',
        url: 'applicants/:id',
      },
    },
  },
  INTEGRATIONS: {
    url: 'integrations',
    children: {
      VERIFICATIONS: {
        path: 'verifications',
        url: 'integrations/verifications',
      },
      CREATE: {
        path: 'create',
        url: 'integrations/create',
      },
      EDIT: {
        path: 'verifications/:id',
        url: 'integrations/verifications',
      },
    },
  },
  SETTINGS: {
    url: 'settings',
    children: {
      BRANDING: {
        path: 'branding',
        url: 'settings/branding',
      },
      PROFILE: {
        path: 'profile',
        url: 'settings/profile',
      },
      ACCOUNT: {
        path: 'account',
        url: 'settings/account',
      },
      TEAM_MEMBERS: {
        path: 'team_members',
        url: 'settings/team_members',
      },
    },
  },
  NOTIFICATIONS: {
    url: 'notifications',
  },
  ORDERS: {
    url: 'orders',
    children: {
      PLANS: {
        path: 'plans',
        url: 'orders/plans',
      },
      LIST: {
        path: 'list',
        url: 'orders/list',
      },
    },
  },
  CHECKOUT: {
    title: 'Checkout',
    url: 'checkout',
    children: {
      PAYMENT: {
        title: 'Payment',
        url: 'payment/:id',
        fullUrl: 'checkout/payment',
      },
      SUCCESS: {
        title: 'Success payment',
        url: 'payment/success',
        fullUrl: 'checkout/payment/success',
      },
      FAILED: {
        title: 'Failed payment',
        url: 'payment/failed',
        fullUrl: 'checkout/payment/failed',
      },
    },
  },
  SUPPORT: {
    url: 'support',
  },
  VERIFICATION: {
    path: 'identify',
    url: 'identify/:id',
  },
};
