import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import { AutoCleanupFeature, Features } from '../../decorators';
import { BaseControlComponent, FormStateDispatcher } from '../../abstracts';

@Component({
  selector: 'kyc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Features([AutoCleanupFeature()])
export class DatePickerComponent extends BaseControlComponent<Date> implements OnInit {
  readonly destroyed$!: Observable<void>;
  readonly control = new FormControl();
  @Input() disableFuture!: boolean;
  @Input() disablePast!: boolean;
  @Input() disable = false;
  @Input() readonly = true;
  @Input() label!: string;
  @Input() startDate!: any;
  @Input() endDate!: any;
  @Input() placeholder: string;
  @Input() startAt = new Date();
  @Input() classes: any[] | undefined = [];
  @ViewChild(MatDatepicker) readonly picker!: MatDatepicker<Date>;

  readonly viewToModelParser = (value: Date | null) => {
    if (value instanceof Date) {
      const utcDate = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
      return new Date(utcDate);
    }
    return value;
  };

  get maxDate() {
    return this.disableFuture ? new Date() : this.endDate ?? null;
  }

  get minDate() {
    return this.disablePast ? new Date() : this.startDate ?? null;
  }

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent | Event) {
    event.preventDefault();
  }

  onFocus() {
    this.picker.open();
    this.onTouched?.();
  }

  onClear() {
    this.control.setValue(null);
  }

  myFilter = (value: any): boolean => {
    if (this.startDate) {
      return new Date(this.startDate) < new Date(value);
    }
    if (this.endDate) {
      return new Date(this.endDate) > new Date(value);
    }
    return true;
  };
}
