import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentTypeEnum, POADocumentTypes } from '../enums';
export const DOCUMENT_TYPES = [
  {
    id: DocumentTypeEnum.Passport,
    value: _('documentType.option.passport'),
  },
  {
    id: DocumentTypeEnum.DriversLicense,
    value: _('documentType.option.driversLicense'),
  },
  {
    id: DocumentTypeEnum.IdCard,
    value: _('documentType.option.idCard'),
  },
  {
    id: DocumentTypeEnum.ResidencePermit,
    value: _('documentType.option.residencePermit'),
  },
  {
    id: DocumentTypeEnum.Visa,
    value: _('documentType.option.visa'),
  },
];

export const ADDRESS_DOCUMENT_TYPE = [
  {
    id: POADocumentTypes.Receipt,
    value: _('documentType.address.option.receipt'),
  },
  {
    id: POADocumentTypes.Invoice,
    value: _('documentType.address.option.invoice'),
  },
  {
    id: POADocumentTypes.UtilityBill,
    value: _('documentType.address.option.utilityBill'),
  },
];

export const ALL_DOCS_TYPE = [...DOCUMENT_TYPES, ...ADDRESS_DOCUMENT_TYPE];
