import { Pipe, PipeTransform } from '@angular/core';
import { ISelectOption, RoleId } from '@shared';

@Pipe({
  name: 'adminRoles',
})
export class AdminRolesPipe implements PipeTransform {
  transform(value: ISelectOption<RoleId>[]) {
    return value.filter((r) => [RoleId.ADMIN, RoleId.SUPER_ADMIN, RoleId.SUPPORT].includes(r.id));
  }
}
