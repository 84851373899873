import { ICommonProps, RoleId, ProfileType, TicketDepartment } from '@shared';
import { IAccount } from '@modules/account/interfaces';

export interface IProfile extends ICommonProps {
  username: string;
  role: RoleId;
  lastauth: string;
  locked: boolean;
  approved: boolean;
  active: boolean;
  phone_number: string;
  tfa_type: number;
  country_id: number;
  company_id: number;
  currency: string;
  ip: string;
  platform: string;
  browser: string;
  referral_link: string;
  inviter_id: number;
  personal_pin: string;
  first_name: string;
  last_name: string;
  logo: string;
  type: ProfileType;
  own: boolean;
  departments?: TicketDepartment[];
  current_session_id: number;
  active_sessions: IActiveSession[];
  old_password?: string;
  password?: string;
  company?: IAccount;
}

export interface ICreateNewAdminDTO {
  first_name: string;
  last_name: string;
  phone_number: string;
  username: string;
  password: string;
  role: RoleId;
  departments: TicketDepartment[];
}

export interface IUserService extends ICommonProps {
  company_id: number;
  data: any;
  invoice_id: number;
  number_of_verification: number;
  plan_id: number;
  state: number;
  type: number;
}

export interface IActiveSession {
  active: boolean;
  applicant_id: number;
  browser: string;
  city: string;
  company_id: number;
  country_id: number;
  device_maker: string;
  device_model: string;
  device_type: number;
  geom_location: string;
  ip: string;
  last_auth: string;
  platform: string;
  _id: number;
}
