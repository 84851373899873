import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthService, GlobalObjectService } from '@core';
import { Observable } from 'rxjs';
import { ROUTES_DATA, ADMIN_LOGGED } from '@shared';

@Component({
  selector: 'kyc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() isMobile = false;
  @Output() openDrawer = new EventEmitter();
  isAdminUser = !!this.globalObjectService.getStorageData(ADMIN_LOGGED);

  ROUTES_DATA = ROUTES_DATA;
  verifications$: Observable<number> = this.authService.getUserVerifications();

  constructor(private authService: AuthService, private globalObjectService: GlobalObjectService) {}

  ngOnInit(): void {
    if (!this.isAdminUser) {
      this.authService.getUserService().subscribe();
    }
  }
}
