<footer class="footer__wrapper">
  <div class="container footer">
    <kyc-logo [isAdminUser]="false"></kyc-logo>

    <div class="footer__block">
      <p>© KYC {{ year }}. {{ 'footer.copyrights.rights' | translate }}</p>
      <a routerLink="/" class="privacy">{{
        'footer.copyrights.policy_privacy' | translate
      }}</a>
    </div>
    <div class="footer__block">
      <p class="follow-us">{{ 'footer.social.follow_us' | translate }}</p>
      <a href="#">
        <kyc-icon icon="facebook"></kyc-icon>
      </a>
      <a href="#">
        <kyc-icon icon="linkedin"></kyc-icon>
      </a>
    </div>
  </div>
</footer>
