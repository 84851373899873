import { ICommonProps } from './API.interface';
import { DocumentTypeEnum } from '../enums';

export interface IVerificationDocumentSettings extends ICommonProps {
  company_id: number;
  country_docs: IVerificationDocuments;
  verification_map_id: number;

  selectedDocuments?: {
    [key in DocumentTypeEnum]: boolean;
  };
}

export interface IVerificationDocuments {
  [key: number]: {
    // country id
    [key in DocumentTypeEnum]: {
      active: boolean;
      sides: {
        [key: number]: IDocumentSide;
      };
    };
  };
}

export interface IDocumentSide {
  description: string;
  active: boolean;
}
