import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RoleId, ServiceType } from '../enums';

export const SERVICE_TYPES = [
  {
    id: ServiceType.Trial,
    value: _('service.type.trial'),
  },
  {
    id: ServiceType.Standard,
    value: _('service.type.standard'),
  },
  {
    id: ServiceType.Pro,
    value: _('service.type.pro'),
  },
  {
    id: ServiceType.Premium,
    value: _('service.type.premium'),
  },
];
