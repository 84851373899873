import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { SortEvent, ITableHeader, SortableDirective } from '@shared';

@Component({
  selector: 'kyc-table-list-wrapper',
  templateUrl: './table-list-wrapper.component.html',
  styleUrls: ['./table-list-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableListWrapperComponent implements OnInit {
  @Input() tableHeaders: ITableHeader[] = [];
  @Input() tableHeaderTemplate: string = 'repeat(6, 1fr)';
  @Output() onSort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  @ViewChildren(SortableDirective)
  headers: QueryList<SortableDirective>;

  constructor() {}

  ngOnInit(): void {}

  onSortWrapper(sort: SortEvent) {
    this.headers.forEach((header) => {
      if (header.kycSortable !== sort.column) {
        header.direction = '';
      }
    });
    this.onSort.emit(sort);
  }
}
