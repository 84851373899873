<a class="ticket-short" *ngIf="ticketInfo" [routerLink]="ticketInfo._id + ''">
  <div class="flex align-items-center">
    <kyc-icon [fontSize]="30" size="fit" class="ticket-short__user">
      person
    </kyc-icon>
    <div class="ticket-short__info">
      <p class="ticket-short__info-title">{{ ticketInfo.name }}</p>

      <ng-container *ngIf="ticketInfo?.last_message_date">
        <ng-container *ngIf="ticketInfo.state === TicketState.Closed">
          <p>
            #{{ ticketInfo._id }} -
            {{ 'support.ticketShort.closedInfo' | translate }}
          </p>
        </ng-container>

        <ng-container *ngIf="ticketInfo.state !== TicketState.Closed">
          <p *ngIf="ticketInfo?.last_message_date">
            #{{ ticketInfo._id }} -
            {{
              'support.response.from'
                | translate: { adminName: lastResponseFrom }
            }}
            <span class="date">{{
              'support.response.days'
                | translate: { count: getDatesFromLastResponse() }
            }}</span>
          </p>
        </ng-container>
      </ng-container>

      <p *ngIf="!ticketInfo?.last_message_date">
        #{{ ticketInfo._id }} - {{ 'support.response.notYet' | translate }}
      </p>
    </div>
  </div>
  <kyc-icon *ngIf="ticketInfo?.has_unread_messages" class="ticket-short__icon"
    >mail</kyc-icon
  >
</a>
