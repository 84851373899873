import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SelfieTypeEnum } from '../enums';
export const SELFIE_TYPES = [
  {
    id: SelfieTypeEnum.SelfieWithDocuments,
    value: _('selfieType.option.withDocuments'),
  },
  {
    id: SelfieTypeEnum.AdvancedLivenessCheck,
    value: _('selfieType.option.advancedLivenessCheck'),
  },
  {
    id: SelfieTypeEnum.WebCameraPhoto,
    value: _('selfieType.option.webCamera'),
  },
];
