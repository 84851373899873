<div class="attachment" *ngIf="attachment || previewAttachment">
  <div class="attachment__info" (click)="onAttachmentUpload.emit(attachment)">
    <div class="attachment__info-icon">
      <kyc-icon>description</kyc-icon>
    </div>
    <span class="attachment__info-name">{{
      downloadMode
        ? attachment?.public_name
        : previewAttachment?.fileToUpload?.name
    }}</span>
    <span class="attachment__info-size">{{
      parseFileSize(
        downloadMode ? attachment?.size : previewAttachment?.fileToUpload?.size
      )
    }}</span>
  </div>

  <kyc-icon
    class="attachment__remove"
    (click)="onAttachmentRemove.emit(attachment)"
    >close</kyc-icon
  >
</div>
