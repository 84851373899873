export enum FormFieldsEnum {
  FirstName = 1,
  LastName = 2,
  MiddleName = 3,
  CountryOfBirth = 4,
  StateOfBirth = 5,
  DateOfBirth = 6,
  PlaceOfBirth = 7,
  LegalName = 8,
  Gender = 9,
  Nationality = 10,
  TaxResidence = 11,
  TaxNumber = 12,
  Country = 13,
  Street = 14,
  BuildingNumber = 15,
  FlatNumber = 16,
  City = 17,
  State = 18,
  ZipCode = 19,
}
