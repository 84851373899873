<header class="header__wrapper">
  <div class="container header">
    <div class="header__logo">
      <kyc-icon *ngIf="isMobile" (click)="openDrawer.emit()">menu</kyc-icon>
      <kyc-logo [isAdminUser]="isAdminUser"></kyc-logo>
    </div>
    <div class="header__actions">
      <a
        [href]="'https://kyc-doc.bdp.in.ua/' | safeURL"
        target="_blank"
        class="header__actions__help"
        >{{ 'common.buttons.help' | translate }}</a
      >
      <kyc-lang-switcher [shortForm]="true"></kyc-lang-switcher>
      <kyc-button
        *ngIf="!isAdminUser"
        variant="stroked"
        color="success"
        [routerLink]="ROUTES_DATA.ORDERS.children.PLANS.url">
        <kyc-icon>verified_user</kyc-icon>
        <span *kycLet="verifications$ | async as verifications">
          {{ verifications }}
        </span>
      </kyc-button>
      <kyc-notification-counter></kyc-notification-counter>
      <kyc-profile-menu [isAdminUser]="isAdminUser"></kyc-profile-menu>
    </div>
  </div>
</header>
