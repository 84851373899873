export type PropType<T extends object, K extends keyof T> = T[K];

export type Dictionary<T = unknown> = {
  [index in PropertyKey]: T;
};

export type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};

export type IdName<TId = number, TName = string> = {
  id: TId;
  name: TName;
};

export type IdValue<TId = number, TValue = string> = {
  id: TId;
  value: TValue;
};

export type Enum<E> = Record<keyof E, number | string> & {
  [k: number]: string;
};
