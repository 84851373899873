import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { ROUTES_DATA } from '@shared';
import { UnauthorizedGuard, AuthGuard } from '@core';
import { environment } from '@env';

const routes: Routes = [
  {
    path: ROUTES_DATA.AUTH.url,
    canActivate: [UnauthorizedGuard],
    canActivateChild: [UnauthorizedGuard],
    loadChildren: () => import('@modules/auth').then((m) => m.AuthModule),
  },
  {
    path: ROUTES_DATA.ADMIN.children.AUTH.url,
    loadChildren: () => import('@modules/auth').then((m) => m.AuthAdminModule),
    data: {
      isAdmin: true,
    },
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: ROUTES_DATA.HOME.url,
        loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: ROUTES_DATA.SUPPORT.url,
        loadChildren: () => import('@modules/ticket/ticket.module').then((m) => m.TicketModule),
      },
      {
        path: ROUTES_DATA.CHECKOUT.url,
        loadChildren: () =>
          import('@modules/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: ROUTES_DATA.APPLICANTS.url,
        loadChildren: () =>
          import('@modules/applicants/applicants.module').then((m) => m.ApplicantsModule),
      },
      {
        path: ROUTES_DATA.INTEGRATIONS.url,
        loadChildren: () =>
          import('@modules/integrations/integrations.module').then((m) => m.IntegrationsModule),
      },
      {
        path: ROUTES_DATA.SETTINGS.url,
        children: [
          {
            path: '',
            redirectTo: ROUTES_DATA.SETTINGS.children.PROFILE.path,
            pathMatch: 'full',
          },
          {
            path: ROUTES_DATA.SETTINGS.children.BRANDING.path,
            loadChildren: () =>
              import('@modules/branding/branding.component').then((m) => m.BrandingModule),
          },
          {
            path: ROUTES_DATA.SETTINGS.children.PROFILE.path,
            loadChildren: () =>
              import('@modules/profile/profile.component').then((m) => m.ProfileModule),
          },
          {
            path: ROUTES_DATA.SETTINGS.children.ACCOUNT.path,
            loadChildren: () =>
              import('@modules/account/account.component').then((m) => m.AccountModule),
          },
          {
            path: ROUTES_DATA.SETTINGS.children.TEAM_MEMBERS.path,
            loadChildren: () =>
              import('@modules/team-members/team-members.component').then(
                (m) => m.TeamMembersModule
              ),
          },
        ],
      },
      {
        path: ROUTES_DATA.NOTIFICATIONS.url,
        loadChildren: () =>
          import('@modules/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: ROUTES_DATA.ORDERS.url,
        loadChildren: () => import('@modules/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: ROUTES_DATA.ADMIN.url,
        loadChildren: () => import('@modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: ROUTES_DATA.VERIFICATION.url,
    loadChildren: () =>
      import('@modules/verification/verification.module').then((m) => m.VerificationModule),
  },
  { path: '**', redirectTo: '/' },
];

if (!environment.production) {
  routes.push({
    path: 'ui',
    loadChildren: () => import('@modules/ui/ui.module').then((m) => m.UiModule),
  });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
