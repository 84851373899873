import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { LoaderService, ApiErrorResponse } from '@shared';
import { AuthService } from '../services';
import { VERIFICATION_TOKEN } from './token.interceptor';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      delay(0),
      tap(() => this.loaderControl(true)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const apiErr = new ApiErrorResponse(error);
          if (apiErr.error._error?.code === 401) {
            request.context.get(VERIFICATION_TOKEN)
              ? this.authService.removeVerificationToken()
              : this.authService.signOut();
          }
          return throwError(() => apiErr);
        }
        return throwError(() => error);
      }),
      finalize(() => this.loaderControl(false))
    );
  }

  private loaderControl(state: boolean) {
    this.loaderService.setLoaderStatus(state);
  }
}
