import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

type StorageType = 'sessionStorage' | 'localStorage';

@Injectable({
  providedIn: 'root',
})
export class GlobalObjectService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  // check if we use browser
  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getWindow(): Window | null {
    return this.doc.defaultView;
  }

  getLocation(): Location {
    return this.doc.location;
  }

  // get data from one of the storage types by key when we use browser
  getStorageData(key: string, type: StorageType = 'localStorage') {
    return this.doc.defaultView[type].getItem(key);
  }

  // set data to one of the storage types by key when we use browser
  setStorageData(key: string, value: string, type: StorageType = 'localStorage') {
    this.doc.defaultView[type].setItem(key, value);
  }

  // remove data from one of the storage types by key when we use browser
  removeStorageData(key: string, type: StorageType = 'localStorage') {
    this.doc.defaultView[type].removeItem(key);
  }

  replaceUrlSegment(segment: string) {
    if (this.isPlatformBrowser()) {
      window.history.replaceState({}, '', segment);
    }
  }

  externalRedirect(url: string, protocol = '_blank') {
    if (this.isPlatformBrowser()) {
      window.open(url, protocol);
    }
  }
}
