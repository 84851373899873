<div class="layout">
  <kyc-header [isMobile]="isMobile" (openDrawer)="openDrawer()"></kyc-header>
  <mat-drawer-container
    *ngIf="defaultContainer"
    class="container main"
    autosize>
    <mat-drawer
      [opened]="navOpened"
      [mode]="isMobile ? 'over' : 'side'"
      #drawer
      class="main__drawer">
      <kyc-nav (toggleNav)="isMobile && openDrawer()"></kyc-nav>
    </mat-drawer>

    <div class="main__content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
  <section *ngIf="!defaultContainer" class="main__full">
    <router-outlet></router-outlet>
  </section>
  <kyc-footer></kyc-footer>
  <kyc-progress-spinner [display]="loader$ | async"></kyc-progress-spinner>
</div>
