import {
  ICommonProps,
  TicketPriority,
  TicketDepartment,
  TicketState,
  IProfile,
  IOutPutData,
} from '@shared';

export interface ISupportTicket extends ICommonProps {
  name: string;
  user_id: number;
  priority: TicketPriority;
  department: TicketDepartment;
  change_state_date: string;
  last_message_date: string;
  has_unread_messages: boolean;
  state: TicketState;
  admin_id: number;
  admin?: Partial<IProfile>;
  user?: IProfile;
  pin_code?: string;
  add_data?: {
    invoice_id?: number;
  };
}

export interface ISupportTicketMessage extends ICommonProps {
  text: string;
  ticket_id: number;
  attachments: string[];
  view_date: string;
  unread: boolean;
  user_id: number;
  admin_id: number;
}

export interface ITicketFormValue {
  theme: string;
  message: string;
  pin_code?: string;
  attachment?: IOutPutData[];
}

export interface IExtendedTicketFormValue extends ITicketFormValue {
  user_id: number;
  department: TicketDepartment;
  priority: TicketPriority;
}
