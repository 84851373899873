import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number) {
    if (!size) return '';
    return size < 1_000_000
      ? `${Math.ceil(size / 1_000)}KB`
      : `${(size / 1_000_000).toPrecision(2)}MB`;
  }
}
