import { ApplicantStateEnum } from '@modules/applicants/interfaces';

export interface IStatistic {
  counts: {
    count: number;
    applicants_state: ApplicantStateEnum;
  }[];
  total: number;
}

export interface IAdminStatistic {
  applicants: number;
  companies: number;
  users: number;
  user_services: {
    active: number;
    cancelled: number;
    expired: number;
    frozen: number;
  };
  tickets: {
    closed: number;
    open: number;
  };
  invoices: {
    declined: number;
    paid: number;
    pending: number;
    wait_confirm: number;
  };
}
