export enum DocumentTypeEnum {
  Passport = 1,
  IdCard = 2,
  DriversLicense = 3,
  ResidencePermit = 4,
  Visa = 5,
}

export enum POADocumentTypes {
  Receipt = 6,
  Invoice = 7,
  UtilityBill = 8,
}

export enum DocumentState {
  Verified = 1,
  Rejected = 2,
  WaitForApprove = 3,
}

export enum DocumentCheck {
  Unknown = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum UploadedBy {
  Applicant = 1,
  User = 2,
}
