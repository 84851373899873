import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FieldTypeEnum } from '../enums';

export const FIELD_TYPES = [
  {
    id: FieldTypeEnum.Str,
    value: _('fieldTypes.value.text'),
  },
  {
    id: FieldTypeEnum.Int,
    value: _('fieldTypes.value.number'),
  },
  {
    id: FieldTypeEnum.Select,
    value: _('fieldTypes.value.select'),
  },
  {
    id: FieldTypeEnum.Bool,
    value: _('fieldTypes.value.boolean'),
  },
  {
    id: FieldTypeEnum.File,
    value: _('fieldTypes.value.file'),
  },
  {
    id: FieldTypeEnum.DateTime,
    value: _('fieldTypes.value.date'),
  },
];
