import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FieldTypeEnum } from '../enums';
export const DEFAULT_FORM_FIELDS = [
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.firstName'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.lastName'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.middleName'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.countryOfBirth'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.stateOfBirth'),
  },
  {
    id: FieldTypeEnum.DateTime,
    value: _('defaultFormFields.option.dateOfBirth'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.placeOfBirth'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.legalName'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.gender'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.nationality'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.taxResidence'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.taxNumber'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.country'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.street'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.buildingNumber'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.flatNumber'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.city'),
  },
  {
    id: FieldTypeEnum.Str,
    value: _('defaultFormFields.option.state'),
  },
  {
    id: FieldTypeEnum.Int,
    value: _('defaultFormFields.option.zipCode'),
  },
];
