import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RoleId } from '../enums';

export const USER_ROLES = [
  {
    id: RoleId.SUPER_ADMIN,
    value: _('support.user.role.superAdmin'),
  },
  {
    id: RoleId.ADMIN,
    value: _('support.user.role.admin'),
  },
  {
    id: RoleId.SUPPORT,
    value: _('support.user.role.support'),
  },
  {
    id: RoleId.USER,
    value: _('support.user.role.user'),
  },
];
