import { Injectable } from '@angular/core';
import { BaseHttpService, IQuery, IResponse, ICommonProps } from '@shared';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { INotification } from '@modules/notifications/interfaces';
import { NotificationFactory } from './notification.factory';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient, readonly factory: NotificationFactory) {
    super('notification');
  }

  getNotifications = (query?: IQuery): Observable<IResponse<INotification>> =>
    this.get<IResponse<INotification>>(query, '').pipe(
      map((res) => ({
        _items: res._items.map(this.factory.fromResponse),
        _meta: res._meta,
      }))
    );

  getNotificationById = (id: string): Observable<INotification> =>
    this.get<INotification>({}, id).pipe(map(this.factory.fromResponse));

  closeNotification = (id: number): Observable<ICommonProps> =>
    this.patch({ viewed: true }, {}, id);
}
