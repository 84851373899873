import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { LoaderService, getLastChildRoute, isNil } from '@shared';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'kyc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;

  isMobile = window.innerWidth < 768;
  navOpened = !this.isMobile;
  defaultContainer = true;
  loader$ = this.loaderService.getLoader$;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mapRouteData();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(this.mapRouteData)
      )
      .subscribe();
  }

  mapRouteData = () => {
    const route = getLastChildRoute(this.activatedRoute);
    const { data } = route.routeConfig;
    this.navOpened = isNil(data?.hideMenu) ? !this.isMobile : !data?.hideMenu;
    this.defaultContainer = data?.defaultContainer ?? true;
  };

  openDrawer() {
    this.drawer.toggle();
  }
}
