import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
export const SNACK_BARS = {
  error: {
    wrong_one_time_password: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.wrong_one_time_password.message'),
    },
    tfa_already_enable: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.tfa_already_enable.message'),
    },
    wrong_sms_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.wrong_sms_code.message'),
    },
    recovery_code_not_received: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.recovery_code_not_received.message'),
    },
    wrong_recovery_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.wrong_recovery_code.message'),
    },
    invalid_value: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_value.message'),
    },
    bad_email: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.bad_email.message'),
    },
    bad_user_phone_number: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.bad_user_phone_number.message'),
    },
    bad_company_phone_number: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.bad_company_phone_number.message'),
    },
    bad_password: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.bad_password.message'),
    },
    username_required: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.username_required.message'),
    },
    password_required: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.password_required.message'),
    },
    new_password_required: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.new_password_required.message'),
    },
    user_archived: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.user_archived.message'),
    },
    invalid_user: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_user.message'),
    },
    link_required: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.link_required.message'),
    },
    invalid_plan: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_plan.message'),
    },
    invalid_user_plan: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_user_plan.message'),
    },
    invalid_search_filter: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_search_filter.message'),
    },
    invalid_search_result: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_search_result.message'),
    },
    invalid_role: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_role.message'),
    },
    invalid_search_param: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_search_param.message'),
    },
    invalid_event: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_event.message'),
    },
    insufficient_funds: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.insufficient_funds.message'),
    },
    invalid_action: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_action.message'),
    },
    invalid_end_date: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_end_date.message'),
    },
    invalid_invite_link: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_invite_link.message'),
    },
    invalid_country: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_country.message'),
    },
    contact_archived: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.contact_archived.message'),
    },
    invalid_contacts: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_contacts.message'),
    },
    invalid_email_confirmation_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_email_confirmation_code.message'),
    },
    invalid_first_name: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_first_name.message'),
    },
    invalid_last_name: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_last_name.message'),
    },
    invalid_address: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_address.message'),
    },
    invalid_city: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_city.message'),
    },
    invalid_region: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_region.message'),
    },
    invalid_zip_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_zip_code.message'),
    },
    invalid_phone_number: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_phone_number.message'),
    },
    invalid_email: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_email.message'),
    },
    file_empty: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.file_empty.message'),
    },
    file_too_large: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.file_too_large.message'),
    },
    invalid_file_ext: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_file_ext.message'),
    },
    error_save_file: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.error_save_file.message'),
    },
    file_not_found: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.file_not_found.message'),
    },
    cannot_change_password: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.cannot_change_password.message'),
    },
    pay_method_not_supported: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.pay_method_not_supported.message'),
    },
    invalid_balance: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_balance.message'),
    },
    not_enough_funds_on_balance: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.not_enough_funds_on_balance.message'),
    },
    not_enough_funds_on_bonus_balance: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.not_enough_funds_on_bonus_balance.message'),
    },
    bonus_balance_frozen: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.bonus_balance_frozen.message'),
    },
    create_invoice: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.create_invoice.message'),
    },
    invalid_invoice: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_invoice.message'),
    },
    error_payment: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.error_payment.message'),
    },
    error_confirm_payment: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.error_confirm_payment.message'),
    },
    invoice_price_is_zero: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invoice_price_is_zero.message'),
    },
    coupon_has_expired: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.coupon_has_expired.message'),
    },
    coupon_already_used: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.coupon_already_used.message'),
    },
    coupon_not_active_yet: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.coupon_not_active_yet.message'),
    },
    invalid_ticket: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_ticket.message'),
    },
    invalid_ticket_state: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_ticket_state.message'),
    },
    change_password: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.change_password.message'),
    },
    profile_duplicated: {
      key: _('snackBars.profile_duplicated.title'),
      value: _('snackBars.profile_duplicated.message'),
    },
    not_confirmed: {
      key: _('snackBars.not_confirmed.title'),
      value: _('snackBars.not_confirmed.message'),
    },
    user_already_approved: {
      key: _('snackBars.user_already_approved.title'),
      value: _('snackBars.user_already_approved.message'),
    },
    username_not_unique: {
      key: _('snackBars.username_not_unique.title'),
      value: _('snackBars.username_not_unique.message'),
    },
    invite_username_already_exists: {
      key: _('snackBars.invite_username_already_exists.title'),
      value: _('snackBars.invite_username_already_exists.message'),
    },
    limit_of_invites_exceeded: {
      key: _('snackBars.limit_of_invites_exceeded.title'),
      value: _('snackBars.limit_of_invites_exceeded.message'),
    },
    invalid_referral_link: {
      key: _('snackBars.invalid_referral_link.title'),
      value: _('snackBars.invalid_referral_link.message'),
    },
    invalid_confirm_link: {
      key: _('snackBars.invalid_confirm_link.title'),
      value: _('snackBars.invalid_confirm_link.message'),
    },
    invalid_password_reset_link: {
      key: _('snackBars.invalid_password_reset_link.title'),
      value: _('snackBars.invalid_password_reset_link.message'),
    },
    action_denied: {
      key: _('snackBars.action_denied.title'),
      value: _('snackBars.action_denied.message'),
    },
    invalid_pin: {
      key: _('snackBars.invalid_pin.title'),
      value: _('snackBars.invalid_pin.message'),
    },
    access_denied: {
      key: _('snackBars.access_denied.title'),
      value: _('snackBars.access_denied.message'),
    },
    coupon_does_not_exist: {
      key: _('snackBars.coupon_does_not_exist.title'),
      value: _('snackBars.coupon_does_not_exist.message'),
    },
    invalid_phone_confirmation_code: {
      key: _('snackBars.invalid_phone_confirmation_code.title'),
      value: _('snackBars.invalid_phone_confirmation_code.message'),
    },
    user_locked: {
      key: _('snackBars.user_locked.title'),
      value: _('snackBars.user_locked.message'),
    },
    unauthorized: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.unauthorized.message'),
    },
    wrong_auth: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.wrong_auth.message'),
    },
    not_enough_verifications_on_balance: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.not_enough_verifications_on_balance.message'),
    },
    invalid_steps: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_steps.message'),
    },
    verification_map_name_already_use: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.verification_map_name_already_use.message'),
    },
    missing_form_fields: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.missing_form_fields.message'),
    },
    invalid_document_list: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_document_list.message'),
    },
    invalid_identifier: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_identifier.message'),
    },
    step_is_disabled: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.step_is_disabled.message'),
    },
    step_has_already_passed: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.step_has_already_passed.message'),
    },
    previous_step_was_not_passed: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.previous_step_was_not_passed.message'),
    },
    invalid_form_field_value: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_form_field_value.message'),
    },
    invalid_selfie: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_selfie.message'),
    },
    invalid_step: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_step.message'),
    },
    invalid_documents: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_documents.message'),
    },
    invalid_documents_country: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_documents_country.message'),
    },
    invalid_documents_side: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_documents_side.message'),
    },
    not_all_document_sides: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.not_all_document_sides.message'),
    },
    not_all_sides_verified: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.not_all_sides_verified.message'),
    },
    selfie_photo_not_similar_to_document: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.selfie_photo_not_similar_to_document.message'),
    },
    invalid_selfie_or_document: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_selfie_or_document.message'),
    },
    poor_quality_of_documents: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.poor_quality_of_documents.message'),
    },
    invalid_first_step: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_first_step.message'),
    },
    step_cannot_be_rejected: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.step_cannot_be_rejected.message'),
    },
    invalid_placeholder: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_placeholder.message'),
    },
    invalid_required_value: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_required_value.message'),
    },
    invalid_field_type: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_field_type.message'),
    },
    invalid_select_options: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_select_options.message'),
    },
    invalid_section_id: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_section_id.message'),
    },
    invalid_form_steps_value: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_form_steps_value.message'),
    },
    applicant_blocked: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.applicant_blocked.message'),
    },
    invalid_applicant: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_applicant.message'),
    },
    applicant_email_blocklisted: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.applicant_email_blocklisted.message'),
    },
    applicant_phone_blocklisted: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.applicant_phone_blocklisted.message'),
    },
    applicant_summary_created_error: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.applicant_summary_created_error.message'),
    },
    applicant_summary_not_generated: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.applicant_summary_not_generated.message'),
    },
    cart_is_empty: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.cart_is_empty.message'),
    },
    invalid_coupon_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_coupon_code.message'),
    },
    coupon_code_not_unique: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.coupon_code_not_unique.message'),
    },
    pdf_not_created: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.pdf_not_created.message'),
    },
    service_not_found: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.service_not_found.message'),
    },
    service_not_active: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.service_not_active.message'),
    },
    invalid_pricing: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_pricing.message'),
    },
    invalid_service_type: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_service_type.message'),
    },
    error_download_file: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.error_download_file.message'),
    },
    wrong_confirmation_code: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.wrong_confirmation_code.message'),
    },
    social_media_auth_error: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.social_media_auth_error.message'),
    },
    invalid_withdrawal_amount: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_withdrawal_amount.message'),
    },
    invalid_withdrawal_state: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_withdrawal_state.message'),
    },
    invalid_log_type: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_log_type.message'),
    },

    missing_required_field: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.missing_required_field.message'),
    },
    parameter_not_allowed: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.parameter_not_allowed.message'),
    },
    company_website_not_unique: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.company_website_not_unique.message'),
    },
    no_company_info: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.no_company_info.message'),
    },
    invalid_email_tld: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_email_tld.message'),
    },
    contacts_are_not_verified: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.contacts_are_not_verified.message'),
    },
    invalid_session: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.invalid_session.message'),
    },
  },
  info: {},
  serverError: {
    key: _('snackBars.common.error.title'),
    value: _('snackBars.serverError.message'),
  },
};
