import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { VerificationEnum, VerificationType } from '../enums';

const COMMON_STEPS = [
  {
    id: VerificationEnum.ConfirmEmail,
    value: _('verificationType.option.email'),
  },
  {
    id: VerificationEnum.Documents,
    value: _('verificationType.option.documents'),
  },
];

export const ADDRESS_VERIFICATION_STEPS = [
  ...COMMON_STEPS,
  {
    id: VerificationEnum.AddressDocuments,
    value: _('verificationType.option.addressDoc'),
  },
  {
    id: VerificationEnum.AddressForm,
    value: _('verificationType.option.addressForm'),
  },
];
export const USER_VERIFICATION_STEPS = [
  ...COMMON_STEPS,
  {
    id: VerificationEnum.Selfie,
    value: _('verificationType.option.selfie'),
  },
  {
    id: VerificationEnum.ConfirmPhone,
    value: _('verificationType.option.phone'),
  },
];

export const FULL_VERIFICATION_STEPS = [
  ...USER_VERIFICATION_STEPS,
  ...ADDRESS_VERIFICATION_STEPS,
  {
    id: VerificationEnum.SurveyForm,
    value: _('verificationType.option.form'),
  },
];

export const VERIFICATION_TYPES = [
  {
    id: VerificationType.User,
    value: _('verificationType.user'),
  },
  {
    id: VerificationType.Address,
    value: _('verificationType.address'),
  },
];
