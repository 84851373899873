import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TicketDepartment } from '../enums';

export const TICKET_DEPARTMENTS = [
  {
    id: TicketDepartment.Support,
    value: _('support.newTicket.department.support'),
  },
  {
    id: TicketDepartment.Technical,
    value: _('support.newTicket.department.technical'),
  },
  {
    id: TicketDepartment.Financial,
    value: _('support.newTicket.department.financial'),
  },
  {
    id: TicketDepartment.Reporting,
    value: _('support.newTicket.department.reporting'),
  },
];
