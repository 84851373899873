<section *ngIf="sectionView">
  <kyc-button dense class="upload" uiDnd (fileDropped)="onFileDropped($event)">
    <label class="upload-label">
      <span class="upload-title">
        <kyc-icon>attach_file</kyc-icon>
        {{ 'global.attachment' | translate }}</span
      >
      <input
        #fileInput
        class="upload-input"
        type="file"
        [multiple]="multiple"
        (change)="fileChangeEvent($event)" />
    </label>
  </kyc-button>

  <small class="maxSize">{{
    'files.maxSize' | translate: { size: fileSizeLimitMB }
  }}</small>
</section>

<kyc-button *ngIf="!sectionView" borderless variant="stroked" color="info">
  <label class="cursor-pointer">
    {{ 'global.uploadFile' | translate }}
    <input
      #fileInput
      class="upload-input"
      type="file"
      [multiple]="true"
      (change)="fileChangeEvent($event)" />
  </label>
</kyc-button>
